import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import AuthService from '../../../services/authentication/auth.service';
import { validationEmail } from '../../../helpers/form/validations';
import { NewPageView } from '../../../containers/NewPageView';
import { InputCustom } from '../../../components/InputCustom';
import { PrimaryButton } from '../../../components/PrimaryButton';
import { SecondaryButton } from '../../../components/SecondaryButton';
import { LoadingCustomModal } from '../../../components/Modals/LoadingCustomModal';

import LogoImg from '../../../assets/images/logo/logo-flora.svg';
import {
  ButtonsContainer,
  Card,
  Container,
  FormContainer,
  ContInput,
  Content,
} from './styles';

const COUNTDOWN_INITIAL_TIME_SECONDS = 59; // 1 minute

export const NewForgotPassword = () => {
  const navigate = useNavigate();
  const inputRef = useRef();
  const [email, setEmail] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [secondsAmount, setSecondsAmount] = useState(
    COUNTDOWN_INITIAL_TIME_SECONDS
  );

  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState({
    mode: null,
    text: 'Enviaremos um e-mail com instruções para você alterar sua senha!',
    text2: null,
  });

  const seconds = secondsAmount % 60;

  const setText = (mode) => {
    let text = '';
    let text2 = '';

    switch (mode) {
      case 'E-MAIL_NOT_FOUND':
        text =
          'Enviaremos um e-mail com instruções para você alterar sua senha!';
        text2 = '';
        break;
      case 'E-MAIL_SENT':
        text = 'E-mail enviado com sucesso!';
        text2 =
          'Não se esqueça de também checar a caixa de spam. Caso não tenha recebido clique no botão abaixo para reenviar o e-mail.';
        break;
    }

    setOption({ ...option, text, text2 });
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleBackToLogin = () => {
    navigate('/login');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);

      await AuthService.requestPasswordReset(email);

      setSecondsAmount(COUNTDOWN_INITIAL_TIME_SECONDS);
      setOption({ mode: 'E-MAIL_SENT' });
    } catch (err) {
      if (err?.status === 401) {
        setOption({ mode: 'E-MAIL_NOT_FOUND' });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (email?.length === 0) return;
    if (validationEmail(email)) {
      setButtonDisabled(false);
      return;
    }
    setButtonDisabled(true);
  }, [email]);

  useEffect(() => {
    const { mode } = option;

    if (!mode) return;
    setText(mode);
  }, [option.mode]);

  useEffect(() => {
    if (secondsAmount > 0 && option.mode === 'E-MAIL_SENT') {
      setTimeout(() => {
        setSecondsAmount((prevState) => prevState - 1);
      }, 1000);
    }
  }, [secondsAmount, option.mode]);

  return (
    <NewPageView>
      <LoadingCustomModal isLoading={loading} />
      <Container>
        <Card>
          <Content>
            <a href='https://floraenergia.com.br/'>
              <img
                src={LogoImg}
                alt='Logo-flora'
              />
            </a>

            <h2>{option.text}</h2>
            <p>{option.text2}</p>

            <FormContainer onSubmit={handleSubmit}>
              <InputCustom
                type='email'
                labelText='E-mail'
                value={email}
                onChange={handleEmail}
                ref={inputRef}
                hasError={option?.mode === 'E-MAIL_NOT_FOUND'}
                hasTouched={option?.mode === 'E-MAIL_NOT_FOUND'}
                errorMessage='E-mail não cadastrado'
                requiredMessage='Email é obrigatório'
              />

              {option?.mode === 'E-MAIL_SENT' && (
                <ButtonsContainer>
                  <PrimaryButton
                    disabled={secondsAmount !== 0}
                    type='submit'
                  >
                    Reenviar e-mail {` `}
                    {seconds ? `(${String(seconds).padStart(2, '0')} s)` : null}
                  </PrimaryButton>
                  <SecondaryButton onClick={handleBackToLogin}>
                    Voltar para login
                  </SecondaryButton>
                </ButtonsContainer>
              )}

              {option?.mode !== 'E-MAIL_SENT' && (
                <PrimaryButton
                  type='submit'
                  disabled={buttonDisabled}
                >
                  Enviar
                </PrimaryButton>
              )}
            </FormContainer>

            <Link to='/cadastro'>Criar minha conta</Link>
          </Content>
        </Card>
      </Container>
    </NewPageView>
  );
};
