import { isBrowser } from '../utils';
import { create } from 'zustand';

import * as jose from 'jose';

export const AUTH_STORAGE_KEY = 'floraenergia:auth';
const discount =
  import.meta.env.REACT_APP_ENV === 'prod'
    ? import.meta.env?.REACT_APP_DISCOUNT?.split(',').map((el) =>
        parseInt(el, 10)
      )
    : [];

export const generalDecode = async (token2) => {
  try {
    //const decoded = jwt.verify(token2, import.meta.env.REACT_APP_API_USER_SECRET);
    const secret = new TextEncoder().encode(
      import.meta.env.REACT_APP_API_USER_SECRET
    );
    const { payload } = await jose.jwtVerify(token2, secret);

    const { type } = payload;
    if (type === 'Array') {
      const payloadArray = [];
      await Promise.all(
        Object.keys(payload).map(async (el) => {
          if (Number.isInteger(parseInt(el, 10))) {
            payloadArray.push(payload[el]);
          }
        })
      );
      return payloadArray;
    }
    return { ...payload };
  } catch (err) {
    if (err.name === 'TokenExpiredError') {
      throw err;
    }
    // window.location.reload();
  }
};

export const decode = async (token2) => {
  try {
    token2 =
      token2 || JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY)).token2;

    const secret = new TextEncoder().encode(
      import.meta.env.REACT_APP_API_USER_SECRET
    );
    const { payload } = await jose.jwtVerify(token2, secret);
    const { id, role } = payload;

    return { ...payload, id: parseInt(id, 10), role };
  } catch (err) {
    const errorsToResetToken = ['JsonWebTokenError', 'TokenExpiredError'];
    if (errorsToResetToken.includes(err?.name))
      localStorage.removeItem('floraenergia:auth');
    // window.location.reload();
  }
};

// decodeSync
export const isTokenValid = () => {
  try {
    const token = JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY))?.token;
    const claims = jose.decodeJwt(token);
    const exp_date = claims.exp;
    const current_date = Math.floor(Date.now() / 1000);
    if (exp_date < current_date) {
      localStorage.removeItem('floraenergia:auth');
      return false;
    }
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const PERSISTED_STATE = isBrowser()
  ? JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY))
  : null;

const INITIAL_STATE = {
  userAuth: PERSISTED_STATE?.token2
    ? await decode(PERSISTED_STATE.token2)
    : null,
};

export const useAuthStore = create((set) => ({
  ...INITIAL_STATE,
  setUserAuth: (payload) => set((state) => ({ ...state, userAuth: payload })),
}));

export const removeAuthData = () => {
  localStorage.removeItem(AUTH_STORAGE_KEY);
  useAuthStore.getState().setUserAuth(null);
};
export const setUser = async (token2) => {
  token2 = token2 || JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY))?.token2;
  const user = await decode(token2);
  useAuthStore.getState().setUserAuth(user);
  return user;
};

// decodeSync
export const isUserAuthenticated = () => {
  try {
    const { token } = JSON.parse(window.localStorage.getItem(AUTH_STORAGE_KEY));
    isTokenValid();
    return !!token;
  } catch (error) {
    localStorage.removeItem(AUTH_STORAGE_KEY);
    return false;
  }
};

export const isCompanyFlora = () => {
  try {
    const userCompany = useAuthStore.getState().userAuth?.company_id;
    return parseInt(userCompany) === 0;
  } catch (error) {
    localStorage.removeItem(AUTH_STORAGE_KEY);
  }
};

export const canEditContract = (contract) => {
  try {
    const { status } = contract;
    if (status !== 'pending_signature' && status !== 'pending_activation')
      return false;
    if (import.meta.env.REACT_APP_ENV !== 'prod') return true;
    const user = useAuthStore.getState().userAuth;
    const { role, id } = user;
    if (role !== 'admin') return false;
    if (!discount.includes(id)) return false;
    return true;
  } catch {
    return false;
  }
};

export const isntAdmin = () => {
  const role = useAuthStore.getState().userAuth?.role;
  if (role !== 'admin') return true;
  return false;
};
