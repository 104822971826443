import loadScript from './importScript';

let Clicksign;

const setupClickSign = async (errorCount = 0) => {
  try {
    await loadScript(
      'Clicksign',
      'https://cdn-public-library.clicksign.com/embedded/embedded.min-1.0.0.js'
    );
    Clicksign = window.Clicksign;
  } catch (error) {
    console.log('🚀 ~ setupClickSign ~ error:', error);
    const milliseccondsToWait = randomNumber(
      1000 * 60 + (2 * errorCount + 1),
      1000 * 60 + (2 * errorCount + 1) * 2
    );

    return setTimeout(() => setupIugu((errorCount += 1)), milliseccondsToWait);
  }
};

export { setupClickSign, Clicksign };
