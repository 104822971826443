import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { H2 } from '../../Interface';
import {
  FieldWrapper,
  Label,
  CustomField,
  CustomErrorMessage,
} from '../../Interface/Form';
import { formMasks } from '../../../helpers/form/masks';
import CEPService from '../../../services/general/cep.service';
import { onlyNumbers, getAddressText } from '../../../helpers/form/formatter';
import Loader from '../../Loader';
import { colors } from '../../../styles/colors';
import { TOAST_MESSAGES } from '../../../helpers/toastMessages';
import { ThemeContext } from '../../../contexts/themeContext';
import { Heading3 } from '../../Interface/Text';

const AddressText = styled.p`
  margin-top: 10px;
  color: ${colors.primary};
  font-size: 20px;
  letter-spacing: 0.085em;
`;

const AddressFields = ({
  readonly = false,
  handleBlur,
  setFieldValue,
  values = undefined,
}) => {
  const [cepNumberMemo, setCepNumberMemo] = useState(null);
  const [fetchingCep, setFetchingCep] = useState(false);
  const { themeValue } = useContext(ThemeContext);

  const fetchCep = async (cep, fieldSetValue) => {
    fieldSetValue('postal_code', cep);
    const cepValue = onlyNumbers(cep);
    setCepNumberMemo(cepValue);

    if (readonly || cepValue.length !== 8 || cepValue === cepNumberMemo) return;

    setFetchingCep(true);
    try {
      const { state, city, street, neighborhood } = await CEPService.get(
        cepValue
      );
      fieldSetValue('state', state, true);
      fieldSetValue('city', city, true);
      fieldSetValue('street', street, true);
      fieldSetValue('neighborhood', neighborhood, true);
    } catch (err) {
      toast.warning(TOAST_MESSAGES.POSTAL_CODE_NOT_FOUND);
      fieldSetValue('postal_code', '');
    } finally {
      setFetchingCep(false);
    }
  };

  return (
    <>
      {!readonly ? (
        <>
          <Grid
            item
            xs={12}
          >
            <Heading3>Endereço de Cobrança</Heading3>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FieldWrapper>
              <Label htmlFor='postal_code'>
                CEP: {fetchingCep && <Loader fontSize={20} />}
              </Label>
              <CustomField
                name='postal_code'
                disabled={readonly}
                fullwidth={1}
                mask={formMasks.postalCode()}
                onChange={(e) => {
                  fetchCep(e.target.value, setFieldValue);
                }}
                onBlur={handleBlur}
                style={{ color: '#484848', margin: '0' }}
              />
              <CustomErrorMessage name='postal_code' />
            </FieldWrapper>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FieldWrapper>
              <Label htmlFor='street'>Logradouro:</Label>
              <CustomField
                name='street'
                disabled={readonly}
                fullwidth={1}
                style={{ color: '#484848', margin: '0' }}
              />
              <CustomErrorMessage name='street' />
            </FieldWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FieldWrapper>
              <Label htmlFor='number'>Número:</Label>
              <CustomField
                name='number'
                disabled={readonly}
                fullwidth={1}
                style={{ color: '#484848', margin: '0' }}
              />
              <CustomErrorMessage name='number' />
            </FieldWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FieldWrapper>
              <Label htmlFor='complement'>Complemento:</Label>
              <CustomField
                name='complement'
                disabled={readonly}
                fullwidth={1}
                style={{ color: '#484848', margin: '0' }}
              />
              <CustomErrorMessage name='complement' />
            </FieldWrapper>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FieldWrapper>
              <Label htmlFor='neighborhood'>Bairro:</Label>
              <CustomField
                name='neighborhood'
                disabled={readonly}
                fullwidth={1}
                style={{ color: '#484848', margin: '0' }}
              />
              <CustomErrorMessage name='neighborhood' />
            </FieldWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FieldWrapper>
              <Label htmlFor='city'>Cidade:</Label>
              <CustomField
                name='city'
                disabled={readonly}
                fullwidth={1}
                style={{ color: '#484848', margin: '0' }}
              />
              <CustomErrorMessage name='city' />
            </FieldWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FieldWrapper>
              <Label htmlFor='state'>Estado:</Label>
              <CustomField
                name='state'
                disabled={readonly}
                fullwidth={1}
                style={{ color: '#484848', margin: '0' }}
              />
              <CustomErrorMessage name='state' />
            </FieldWrapper>
          </Grid>
        </>
      ) : (
        <Grid
          item
          xs={12}
          style={{ marginTop: ' 5px' }}
        >
          <Label
            style={{
              textWrap: 'noWrap',
              fontWeight: '600',
            }}
          >
            Endereço de Cobrança:
          </Label>

          {/* <AddressText>{getAddressText(values)}</AddressText> */}
          <Grid
            container
            style={{ marginTop: '10px' }}
          >
            <Grid
              item
              xs={12}
            >
              <FieldWrapper>
                <Label htmlFor='street'>Rua</Label>
                <CustomField
                  name='street'
                  disabled={readonly}
                  fullwidth={1}
                  style={{ color: 'black', margin: '0' }}
                />
                <CustomErrorMessage name='street' />
              </FieldWrapper>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ marginTop: '5px' }}
            >
              <FieldWrapper>
                <Label htmlFor='number'>Número:</Label>
                <CustomField
                  name='number'
                  disabled={readonly}
                  fullwidth={1}
                  style={{ color: 'black', margin: '0' }}
                />
                <CustomErrorMessage name='number' />
              </FieldWrapper>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ marginTop: '5px' }}
            >
              <FieldWrapper>
                <Label htmlFor='complement'>Complemento:</Label>
                <CustomField
                  name='complement'
                  disabled={readonly}
                  fullwidth={1}
                  style={{ color: 'black', margin: '0' }}
                />
                <CustomErrorMessage name='complement' />
              </FieldWrapper>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ marginTop: '5px' }}
            >
              <FieldWrapper>
                <Label htmlFor='neighborhood'>Bairro:</Label>
                <CustomField
                  name='neighborhood'
                  disabled={readonly}
                  fullwidth={1}
                  style={{ color: 'black', margin: '0' }}
                />
                <CustomErrorMessage name='neighborhood' />
              </FieldWrapper>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ marginTop: '5px' }}
            >
              <FieldWrapper>
                <Label htmlFor='postal_code'>
                  CEP: {fetchingCep && <Loader fontSize={20} />}
                </Label>
                <CustomField
                  name='postal_code'
                  disabled={readonly}
                  fullwidth={1}
                  mask={formMasks.postalCode()}
                  onChange={(e) => {
                    fetchCep(e.target.value, setFieldValue);
                  }}
                  onBlur={handleBlur}
                  style={{ color: 'black', margin: '0' }}
                />
                <CustomErrorMessage name='postal_code' />
              </FieldWrapper>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ marginTop: '5px' }}
            >
              <FieldWrapper>
                <Label htmlFor='city'>Cidade:</Label>
                <CustomField
                  name='city'
                  disabled={readonly}
                  fullwidth={1}
                  style={{ color: 'black', margin: '0' }}
                />
                <CustomErrorMessage name='city' />
              </FieldWrapper>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ marginTop: '5px' }}
            >
              <FieldWrapper>
                <Label htmlFor='state'>Estado:</Label>
                <CustomField
                  name='state'
                  disabled={readonly}
                  fullwidth={1}
                  style={{ color: 'black', margin: '0' }}
                />
                <CustomErrorMessage name='state' />
              </FieldWrapper>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

AddressFields.propTypes = {
  readonly: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    street: PropTypes.string,
    number: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    neighborhood: PropTypes.string,
    complement: PropTypes.string,
  }),
};

export default AddressFields;
