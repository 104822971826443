import {
  FaFileAlt,
  FaFunnelDollar,
  FaRegFileAlt,
  FaLightbulb,
  FaFileInvoiceDollar,
  FaBolt,
  FaBusinessTime,
  FaRegMoneyBillAlt,
  FaRegEnvelope,
  FaCloudDownloadAlt,
} from 'react-icons/fa';

import HomeImg from '../assets/images/icons/negative-png/inicio 3.png';
import PropertiesImg from '../assets/images/icons/negative-png/imoveis.png';
import ProfileImg from '../assets/images/icons/negative-png/quem sou.png';
import LogoutImg from '../assets/images/icons/negative-png/sair.png';

import { MdFileUpload } from 'react-icons/md';

import { USER_ROLES_TYPES } from '../helpers/userRoles';

const NAVBAR_ITEMS = Object.freeze([
  {
    isImage: true,
    Icon: HomeImg,
    text: 'Início',
    to: '/home',
    forRoles: [USER_ROLES_TYPES.Customer],
  },
  {
    isImage: true,
    Icon: PropertiesImg,
    text: 'Imóveis',
    to: '/imoveis',
    forRoles: [USER_ROLES_TYPES.Customer],
  },
  {
    isImage: true,
    Icon: ProfileImg,
    text: 'Perfil',
    to: '/perfil',
    forRoles: [USER_ROLES_TYPES.Customer],
  },
  {
    Icon: FaFileAlt,
    text: 'Contratos',
    to: '/contratos',
    forRoles: [USER_ROLES_TYPES.Admin],
  },
  {
    Icon: FaLightbulb,
    text: 'Set Up',
    to: '/set-up',
    forRoles: [USER_ROLES_TYPES.Admin],
  },
  {
    Icon: FaFileInvoiceDollar,
    text: 'Gestão de Pagamentos',
    to: '/gestao-de-pagamentos',
    forRoles: [USER_ROLES_TYPES.Admin],
  },
  {
    Icon: FaFileInvoiceDollar,
    text: 'Todas Faturas',
    to: '/todas-faturas',
    forRoles: [USER_ROLES_TYPES.Admin],
  },
  {
    Icon: FaFileInvoiceDollar,
    text: 'Gestão de Gifts',
    to: '/giftManagement',
    forRoles: [USER_ROLES_TYPES.Admin],
  },
  {
    Icon: FaRegFileAlt,
    text: 'Códigos Promocionais',
    to: '/promocodes',
    forRoles: [USER_ROLES_TYPES.Admin],
  },
  {
    Icon: FaBolt,
    text: 'Gestão de Oferta',
    to: '/gestao-oferta',
    forRoles: [USER_ROLES_TYPES.Admin],
  },
  {
    Icon: FaRegMoneyBillAlt,
    text: 'Gestão Faturamento',
    to: '/gestaoFaturamento',
    forRoles: [USER_ROLES_TYPES.Admin],
  },
  {
    Icon: FaCloudDownloadAlt,
    text: 'Download Bases',
    to: '/download-bases',
    forRoles: [USER_ROLES_TYPES.Admin],
  },
  {
    Icon: MdFileUpload,
    text: 'Faturamento Manual',
    to: '/faturamentoManual',
    forRoles: [USER_ROLES_TYPES.Admin],
  },
  {
    Icon: FaFileAlt,
    text: 'Perdidos',
    to: '/perdidos',
    forRoles: [USER_ROLES_TYPES.Admin],
  },
  {
    Icon: FaRegEnvelope,
    text: 'Disparo Massivo de Emails',
    to: '/envio-emails',
    forRoles: [USER_ROLES_TYPES.Admin],
  },
  {
    isImage: true,
    Icon: LogoutImg,
    text: 'Sair',
    to: '/logout',
    forRoles: [...Object.values(USER_ROLES_TYPES)],
  },
]);

export default NAVBAR_ITEMS;
