import { Alert, Button, CircularProgress } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import styled, { css } from 'styled-components';
import { TOAST_MESSAGES } from '../../helpers/toastMessages';
import ExportingDatabasesService from '../../services/administrator/ManagementBases/ExportingDatabasesService';
import DownloadBases from '../DownloadBases';
import Filter from '../Filter';
import { H1 } from '../Interface';
import Table from '../Table/table';
import FlexRowContainer from '../../componentsStyle/FlexRowContainer';
import FormBasesManagement from '../FormBasesManagement';
import { Heading1 } from '../Interface/Text';

const SEND_TYPE = {
  s3: 's3',
  email: 'email',
  sync: 'sync',
};

const DATA_TYPE = {
  json: 'json',
  xlsx: 'xlsx',
};

const defaultLimit = import.meta.env.REACT_APP_DEFAULT_PAGINATION_LIMIT || 200;

const BasesManagement = ({
  indentifier,
  hasUpdateBase = false,
  hasFilter = false,
  hasPeriod = false,
  hasSelectItems = false,
  buttonSelectItems = null,
  title,
  maxHeighComponent = window.innerHeight - 500,
  filterBasesManagement = {},
  type,
  sharedRefreshedAt = { refreshedAt: null, setRefreshedAt: () => {} },
  extraComponents = {
    topComponents: [],
    buttonComponents: [],
    flexDirection: 'column',
  },
  setHasUpdateDataTable,
  additionalFormData = {},
  externalTriggerUpdateData = false,
}) => {
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [filter, setFilter] = useState([]);
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [period, setPeriod] = useState({});
  const [data, setData] = useState({});
  const [reload, setReload] = useState(false);
  const [hasUpdateData, setHasUpdateData] = useState(false);
  const [offset, setOffeset] = useState(0);
  const [limit, setLimit] = useState(parseInt(defaultLimit));
  const componentRef = useRef(null);
  const [widthComponent, setWidthComponent] = useState('100%');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const requestFunctionSelect = async () => {
    const requestFunction = buttonSelectItems?.requestFunction;

    try {
      await requestFunction({ ids: selectedRowsIds });
    } catch (error) {
      toast.error(TOAST_MESSAGES.ERROR);
    }
  };

  const incrementPayload = () => {
    const payload = {};
    for (let key in filterBasesManagement) {
      const value = filterBasesManagement[key];
      payload['filter'] = { ...payload['filter'], [key]: value };
    }

    filter.forEach((item) => {
      const {
        accessor,
        term: { value },
      } = item;

      payload['filter'] = { ...payload['filter'], [accessor]: value };
    });
    for (let key in period) {
      if (period[key]) payload[key] = new Date(period[key]).toISOString();
    }
    return payload;
  };

  useEffect(() => {
    const width = componentRef?.current?.getBoundingClientRect()?.width;
    setWidthComponent(width);
  }, [componentRef.current, windowWidth]);

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  useEffect(() => {
    const getData = async () => {
      setIsLoadingData(true);
      const payload = {
        sendType: SEND_TYPE['sync'],
        dataType: DATA_TYPE['xlsx'],
      };

      Object.assign(payload, incrementPayload());

      try {
        const result = {};

        if (type === 'editFormRequest') {
          const resultGet = await ExportingDatabasesService.form(indentifier);
          Object.assign(result, resultGet);
        } else {
          const resultGet = await ExportingDatabasesService.table(
            indentifier,
            payload
          );

          Object.assign(result, resultGet);
        }
        setData(result);
        setHasError(false);
      } catch (error) {
        setHasError(true);
      } finally {
        setIsLoadingPage(false);
        setIsLoadingData(false);
        setReload(false);
        setHasUpdateData(false);
      }
    };
    if (isLoadingPage || reload || hasUpdateData || externalTriggerUpdateData)
      getData();
  }, [filter, period, hasUpdateData, externalTriggerUpdateData]);

  const updateData = (obj1, obj2) => {
    if (obj1 === null) return obj2;
    Object.entries(obj1).forEach(([key, value]) => {
      if (typeof value === 'object') {
        if (Array.isArray(value) && key !== 'columns') {
          obj1[key] = value.concat(obj2[key]);
        } else {
          obj1[key] = updateData(value, obj2[key]);
        }
      } else {
        obj1[key] = obj2[key];
      }
    });
    return obj1;
  };

  const updateDataOnScroll = async () => {
    const payload = {
      sendType: SEND_TYPE['sync'],
      dataType: DATA_TYPE['xlsx'],
    };

    Object.assign(payload, incrementPayload());

    const newOffset = offset + parseInt(defaultLimit);
    const newLimit = limit + parseInt(defaultLimit);
    setLimit(newLimit);
    setOffeset(newOffset);

    try {
      const newDataTable = await ExportingDatabasesService.table(
        indentifier,
        payload,
        newOffset,
        newLimit
      );
      setData(updateData(data, newDataTable));
      setHasError(false);
    } catch (error) {
      setLimit(newLimit - parseInt(defaultLimit));
      setOffeset(newOffset - parseInt(defaultLimit));
      toast.error(TOAST_MESSAGES.ERROR);
    }
  };

  const loadAllData = async () => {
    setIsLoadingData(true);
    const payload = {
      //sendType: SEND_TYPE['sync'],
      dataType: DATA_TYPE['json'],
    };
    Object.assign(payload, incrementPayload());
    try {
      const result = await ExportingDatabasesService.getTableS3(
        indentifier,
        payload
      );
      setData(result);
      setHasError(false);
    } catch (error) {
      toast.error(TOAST_MESSAGES.ERROR);
    } finally {
      setIsLoadingData(false);
    }
  };

  return (
    <>
      <Container>
        {isLoadingPage ? (
          <ContainerLoading>
            <CircularProgress />
          </ContainerLoading>
        ) : (
          <>
            <Heading1>{title}</Heading1>

            {hasError ? (
              <Alert severity='error'>
                Desculpe, houve um erro durante o carregamento da página. Caso
                esteja no processo de atualização da base, tente novamente mais
                tarde.
              </Alert>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: extraComponents.flexDirection,
                  alignItems:
                    extraComponents.flexDirection === 'row' ? 'center' : '',
                }}
              >
                {extraComponents.topComponents.map((component) => component)}
                {type === 'table' ||
                type === 'tableForm' ||
                (!type && data.type === 'table') ? (
                  <>
                    <Filter
                      hasFilter={hasFilter}
                      hasPeriod={hasPeriod}
                      columns={_.cloneDeep(data.params.columns)}
                      setFilter={setFilter}
                      setPeriod={setPeriod}
                      setReload={setReload}
                      period={period}
                      isLoading={isLoadingData}
                      loadAllData={loadAllData}
                    />

                    <ActionsContainer buttonSelectItems={!!buttonSelectItems}>
                      {buttonSelectItems ? (
                        <Button
                          variant='outlined'
                          onClick={requestFunctionSelect}
                          disabled={
                            selectedRowsIds.length === 0 ||
                            buttonSelectItems?.disabled
                          }
                          size='small'
                        >
                          {buttonSelectItems.text}
                        </Button>
                      ) : null}
                      <DownloadBases
                        hasUpdateBase={hasUpdateBase}
                        indentifier={indentifier}
                        filter={filter}
                        period={period}
                        filterBasesManagement={filterBasesManagement}
                        refreshedAt={data?.base?.refreshed_at}
                        sharedRefreshedAt={sharedRefreshedAt}
                        style={{ flexDirection: 'row' }}
                      />
                    </ActionsContainer>

                    <div
                      style={{ width: '100%', overflow: 'auto' }}
                      ref={componentRef}
                    >
                      <Table
                        data={data}
                        hasUseRowSelect={hasSelectItems}
                        setSelectedIds={setSelectedRowsIds}
                        maxHeighComponent={maxHeighComponent}
                        widthComponent={widthComponent}
                        updateDataOnScroll={updateDataOnScroll}
                        setHasUpdateData={setHasUpdateData}
                        hasUpdateData={hasUpdateData}
                      />
                    </div>
                  </>
                ) : type === 'download' ||
                  (!type && data.type === 'download') ? (
                  <FlexRowContainer
                    style={{ justifyContent: 'space-between', flexGrow: 1 }}
                  >
                    <Filter
                      hasFilter={hasFilter}
                      hasPeriod={hasPeriod}
                      setFilter={setFilter}
                      setPeriod={setPeriod}
                      setReload={setReload}
                      period={period}
                      isLoading={isLoadingData}
                    />
                    <DownloadBases
                      hasUpdateBase={hasUpdateBase}
                      indentifier={indentifier}
                      filter={filter}
                      period={period}
                      refreshedAt={data?.base?.refreshed_at}
                      sharedRefreshedAt={sharedRefreshedAt}
                      style={{ flexDirection: 'row' }}
                    />
                  </FlexRowContainer>
                ) : type === 'editFormRequest' ||
                  (!type && data.type === 'editFormRequest') ? (
                  <FormBasesManagement
                    data={data}
                    setHasUpdateDataTable={setHasUpdateDataTable}
                    additionalFormData={additionalFormData}
                  />
                ) : null}
                {extraComponents.buttonComponents.map((component) => component)}
              </div>
            )}
          </>
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  padding: 10px;
`;

const ContainerLoading = styled.div`
  display: flex;
  flex-grow: 1;
  height: 300px;
  min-width: 400px;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  ${(props) =>
    !props.buttonSelectItems &&
    css`
      justify-content: end;
    `}
`;

BasesManagement.propTypes = {
  indentifier: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  hasUpdateBase: PropTypes.bool,
  hasFilter: PropTypes.bool,
  hasPeriod: PropTypes.bool,
  hasSelectItems: PropTypes.bool,
  buttonSelectItems: PropTypes.object,
  title: PropTypes.string,
  maxHeighComponent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  sharedRefreshedAt: PropTypes.object,
  extraComponents: PropTypes.object,
};

export default BasesManagement;
