import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;

  label {
    color: #1c1b1f;
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;

    &:last-child {
      margin-left: 11px;
    }
  }

  a {
    color: ${theme.palette.primary.main};
    font-weight: 500;
  }
`;

export const Content = styled.label`
  position: relative;
  width: 18px;
  height: 18px;

  input {
    appearance: none;
    height: 18px;
    width: 18px;

    &:checked + span {
      background: ${theme.palette.green.main};
    }

    &:checked + span:after {
      opacity: 1;
    }
  }

  span {
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 2px solid ${theme.palette.green.main};
    position: absolute;
    border-radius: 2px;

    &:after {
      content: '';
      /* top: -0.125rem;
      left: 0.156rem;
      width: 0.313rem;
      height: 0.625rem; */

      top: -1px;
      left: 3px;
      width: 8px;
      height: 13px;

      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      opacity: 0;
    }
  }
`;
