import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ContractsService from '../../services/general/contracts.service';
import UsersService from '../../services/general/users.service';
import AuthService from '../../services/authentication/auth.service';
import { AUTH_STORAGE_KEY } from '../../store';
import { isTokenValid } from '../../store';
import { errorHandler } from './helpers.js/errorHandler';
import { getHandledPayload } from './helpers.js/payload';
import { stage0Exceptions } from './helpers.js/stageExceptions/0';

export const StageContext = createContext();

export const StageProvider = ({ children }) => {
  const initialState = {
    name: { value: '', status: 'awaiting', touched: false },
    phone_number: { value: '', status: 'awaiting', touched: false },
    email: { value: '', status: 'awaiting', touched: false, alreadyUsed: [] },
    promocode: { value: '', status: 'awaiting', touched: false },
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_id: '',
    utm_term: '',
    utm_content: '',
    utm_extra_data: {},
    attachment: '',
    no_electricity_bill: false,
    postal_code_isCorrectly: false,
    estimated_electric_bill: { value: 0, status: 'awaiting', touched: false },
    postal_code: { value: '', status: 'awaiting', touched: false },
    state: { value: '', status: 'awaiting', touched: false },
    city: { value: '', status: 'awaiting', touched: false },
    street: { value: '', status: 'awaiting', touched: false },
    neighborhood: { value: '', status: 'awaiting', touched: false },
    number: { value: '', status: 'awaiting', touched: false },
    complement: { value: '', status: 'awaiting', touched: false },
    terms_of_service: false,
    provider: '',
    profile: '',
    provider_name: '',
    document: { value: '', status: 'awaiting', touched: false },
    holder: {
      value: '',
      initialValue: '',
      status: 'awaiting',
      touched: false,
    },
    document_ocr: '',
    signer: {
      status: 'awaiting',
      touched: false,
      type: '',
      request_signature_key: '',
    },
    volume: 0,
    options: {
      isTheHolder: true,
      disableIsTheHolderChange: false,
      ocrSuccess: false,
    },
    stage: 0,
    max_stage: 0,
  };
  const [stagePayload, setStagePayload] = useState(initialState);

  const resetStagePayload = () => setStagePayload(initialState);

  const location = useLocation();
  const pathname = location?.pathname;

  const fluxPath = ['/cadastro', '/economize', '/imoveis/novo'];
  const shouldReset = !fluxPath.includes(pathname);

  useEffect(() => {
    if (!shouldReset) return;
    resetStagePayload();
  }, [shouldReset]);

  const changePayload = async (payload) => {
    setStagePayload((prevState) => ({ ...prevState, ...payload }));
  };

  const getStageObject = (stagePayload, actualStage) => {
    const targetStage = actualStage + 1;
    const maxStage = getMaxStage(stagePayload, targetStage);

    return { stage: targetStage, max_stage: maxStage };
  };

  const getMaxStage = (stagePayload, targetStage) => {
    const { max_stage: oldMaxStage } = stagePayload || {};

    return Math.max(oldMaxStage, targetStage);
  };

  const sendPayloadGlobal = async (
    payload,
    isNewPropertyOrHolder,
    functions
  ) => {
    const { setLoading } = functions;
    setLoading(true);

    const handledPayload = getHandledPayload(payload);

    const { email, signer: oldSigner } = stagePayload;

    const { contract_id, stage } = stagePayload;
    if (isNewPropertyOrHolder === true && isTokenValid() === false) {
      window.location.href = '/login';
      return;
    }

    if (stage !== 0) {
      if (localStorage.getItem(AUTH_STORAGE_KEY) === null) {
        await AuthService.login(email.value);
      } else {
        if (!isTokenValid()) await AuthService.login(email.value);
      }
    }

    try {
      const data = isNewPropertyOrHolder
        ? await ContractsService.newLocationNew(handledPayload, contract_id)
        : await UsersService.signupUpdateCheckNew(handledPayload);

      const { signer } = data;

      const stageObject = getStageObject(stagePayload, stage);

      const defaultPayload = { ...stageObject };

      switch (stage) {
        case 0:
          const { isLastContractEmitted } = data;
          const { resumeHandler } = functions;
          if (isLastContractEmitted && !isNewPropertyOrHolder)
            return await resumeHandler();

          const stage0HandledPayload = stage0Exceptions(
            data,
            isNewPropertyOrHolder,
            functions,
            stagePayload
          );

          const finalPayload = { ...stage0HandledPayload, ...defaultPayload };

          changePayload(finalPayload);

          break;
        case 1:
        case 2:
          changePayload({
            ...defaultPayload,
            signer: { ...oldSigner, ...signer },
          });
          break;
        case 4:
          changePayload({ signer: { ...oldSigner, ...signer } });

          break;
      }
    } catch (err) {
      errorHandler(err, stagePayload, functions, changePayload);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StageContext.Provider
      value={{
        stagePayload,
        changePayload,
        sendPayloadGlobal,
        resetStagePayload,
      }}
    >
      {children}
    </StageContext.Provider>
  );
};
