import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../styles/colors';
import theme from '../../theme';

const BaseButton = ({
  className,
  handleClick = undefined,
  text,
  isLoading = false,
  icon = false,
  reverse = false,
  disabled = false,
  outlined = false,
  color = 'orange',
}) => (
  <button
    className={className}
    type='submit'
    onClick={handleClick}
    disabled={disabled}
  >
    {reverse ? (
      <>
        {!isLoading ? text : 'Carregando...'}
        {icon && icon}
      </>
    ) : (
      <>
        {icon && icon}
        {!isLoading ? text : 'Carregando...'}
      </>
    )}
  </button>
);

BaseButton.propTypes = {
  className: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  icon: PropTypes.any,
  reverse: PropTypes.bool,
  disabled: PropTypes.bool,
  outlined: PropTypes.bool,
  color: PropTypes.oneOf([...Object.keys(colors)]),
};

const getButtonTextColor = (props) => {
  return theme.palette.primary.main;
};

const Button = styled(BaseButton)`
  background-color: ${(props) =>
    props.outlined ? colors.white : theme.palette.secondary.main};
  color: ${(props) => getButtonTextColor(props)};
  border: ${(props) =>
    props.outlined ? `1px solid ${theme.palette.primary.main}` : 'unset'};
  outline: none;
  font-size: 20px;
  border-radius: ${(props) => (props.rectangle ? '4px' : '75px')};
  line-height: 21px;
  max-width: 197px;
  max-height: 48px;
  padding: 10px 20px;
  cursor: pointer;

  &:disabled {
    background-color: ${colors.gray};
    cursor: not-allowed;
  }
`;

export default Button;
