import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';
import PageView from '../../../containers/PageView';
import SearchableTable from '../../../components/Interface/SearchableTable';
import { Paragraph } from '../../../components/Interface/Text/Paragraph';
import ContentCard from '../../../components/ContentCard';
import PromocodesService from '../../../services/administrator/promocodes.service';
import PromocodesBaseService from '../../../services/administrator/ManagementBases/promocodesBase.service';

import Button from '../../../components/Button';
import PromocodesForm from '../../../components/Forms/PromocodesForm';

import { TOAST_MESSAGES } from '../../../helpers/toastMessages';
import PromocodeEditForm from '../../../components/Forms/PromocodesEditForm';
import { formatDate } from '../../../helpers/date';
import theme from '../../../theme';

const COLUMNS = [
  { name: 'Id' },
  { name: 'Código promocional' },
  { name: 'Número de colaboradores' },
  { name: 'Ativo em', align: 'center' },
  { name: 'Cadastros', align: 'center' },
  { name: 'Adesões totais', align: 'center' },
  { name: 'Adesões com conta de luz', align: 'center' },
  { name: 'Recebendo energia', align: 'center' },
  { name: 'Cancelamentos', align: 'center' },
  { name: 'Valor injetado (kWh)', align: 'center' },
  { name: 'Economia gerada (R$)', align: 'center' },
  { name: 'Árvores Plantadas', align: 'center' },
  { name: 'Co2 evitadado', align: 'center' },
];

const AdminPromocodes = () => {
  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const [open2, setOpen2] = useState(false);
  const [promocodeToEdit, setPromocodeToEdit] = useState(null);
  const [loadingg, setLoadingg] = useState(false);
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    promocodes: [],
  });
  const [isEditing, setIsEditing] = useState(false);
  const [reload, setReload] = useState(false);

  const getHandledValue = (value) =>
    value ? value.toLocaleString('pt-BR') : '-';

  const getRows = (promocodes) => {
    return promocodes.map(
      (
        {
          id,
          code,
          contributors,
          created_at: createdAt,
          active_at: activeAt,
          total_registers: registers,
          total_signatures: signatures,
          total_signatures_with_bill: signaturesWithBill,
          total_signatures_getting_energy: signaturesGettingEnergy,
          total_cancelled: cancelled,
          total_compensated: compensated,
          total_planted_trees,
          total_savings,
          total_carbon_footprint_avoided,
        },
        index
      ) => ({
        values: [
          {
            value: (
              <Inner style={{ display: 'flex', alignItems: 'center' }}>
                <Paragraph>{id || index + 1}</Paragraph>
                <EditIcon
                  id='editIcon'
                  onClick={() => handleEditPromocode(id)}
                />
              </Inner>
            ),
          },
          {
            value: <Paragraph>{code?.toUpperCase() || code || '-'}</Paragraph>,
          },
          {
            value: <Paragraph>{contributors || '-'}</Paragraph>,
          },
          {
            value: <Paragraph>{formatDate(activeAt)}</Paragraph>,
            align: 'center',
          },
          {
            value: <Paragraph>{registers || '-'}</Paragraph>,
          },
          {
            value: <Paragraph>{signatures || '-'}</Paragraph>,
          },
          {
            value: <Paragraph>{signaturesWithBill || '-'}</Paragraph>,
          },
          {
            value: <Paragraph>{signaturesGettingEnergy || '-'}</Paragraph>,
          },
          {
            value: <Paragraph>{cancelled || '-'}</Paragraph>,
          },
          {
            value: (
              <Paragraph>
                {compensated
                  ? `${parseFloat(compensated)
                      .toFixed(2)
                      .toString()
                      .replaceAll('.', ',')} kWh`
                  : '-'}
              </Paragraph>
            ),
          },

          {
            value: <Paragraph>{getHandledValue(total_savings)}</Paragraph>,
            align: 'center',
          },
          {
            value: (
              <Paragraph>{getHandledValue(total_planted_trees)}</Paragraph>
            ),
            align: 'center',
          },
          {
            value: (
              <Paragraph>
                {getHandledValue(total_carbon_footprint_avoided)}
              </Paragraph>
            ),
            align: 'center',
          },
        ],
      })
    );
  };

  useEffect(() => {
    const fetchPromocodes = async () => {
      try {
        const resources = await PromocodesBaseService.getAll();
        setPageState((state) => ({
          ...state,
          loading: false,
          promocodes: resources,
        }));
      } catch (err) {
        setPageState((state) => ({ ...state, loading: false, hasError: true }));
      }
    };

    fetchPromocodes();
  }, [reload]);

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setPromocodeToEdit(null);
    setOpen2(false);
  };

  const handleEditPromocode = async (id) => {
    try {
      const promocodeToEdit = await promocodes.find((el) => {
        return el.id === id;
      });
      setPromocodeToEdit(promocodeToEdit);
      handleOpen2();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditPromocodeReq = async (payload) => {
    try {
      const req = await PromocodesService.update(payload);
      if (req.success) {
        toast.success(TOAST_MESSAGES.SUCCESS);
        handleClose2();
        setReload(!reload);
      }
    } catch (error) {
      toast.error(TOAST_MESSAGES.ERROR);
    }
  };

  const onSubmit = async (data, { setSubmitting }) => {
    setSubmitting(true);
    setRequestError(false);
    const { code } = data;
    if (code) data = { ...data, code: data?.code?.toLowerCase() };

    try {
      await PromocodesService.create({ ...data });
      toast.success(TOAST_MESSAGES.SUCCESS);
    } catch (err) {
      if (err?.data?.message === 'promocodeAlreadyExists')
        toast.error('O respectivo código promocional já existe');
      else toast.error(TOAST_MESSAGES.ERROR);

      setRequestError(true);
      const { error: msg, errors } = err.response.data;

      if (msg) {
        setErrorMessage(msg);
      }

      if (errors) {
        const text = Object.keys(errors)
          .map((field) => [field, errors[field].join('\n')])
          .map((partial) => partial.join(' '))
          .join('\n');

        setErrorMessage(text);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const { loading, hasError, promocodes } = pageState;

  return (
    <PageView
      title='Códigos Promocionais'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          {!isEditing && (
            <Grid
              container
              justify='flex-end'
            >
              <Button
                rectangle
                text='Novo Código'
                handleClick={() => setIsEditing(true)}
              />
            </Grid>
          )}
          {isEditing && (
            <Grid
              container
              justify='flex-end'
            >
              <Button
                outlined
                text='Omitir'
                handleClick={() => setIsEditing(false)}
                backgroundColor='white'
              />
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
        >
          {isEditing && (
            <ContentCard>
              <PromocodesForm onSubmit={onSubmit} />
            </ContentCard>
          )}
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ContentCard>
            <SearchableTable
              columns={COLUMNS}
              rowsValues={getRows(promocodes)}
              noItemsText='Nenhum promocode'
              dataIsComplete
            />
          </ContentCard>
        </Grid>
      </Grid>
      <div>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={open2}
          onClose={handleClose2}
          closeAfterTransition
        >
          <div
            style={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Fade in={open2}>
              <ModalDiv>
                <PromocodeEditForm
                  handleClose={handleClose2}
                  promocode={promocodeToEdit}
                  loadingg={loadingg}
                  setLoadingg={setLoadingg}
                  reload={reload}
                  setReload={setReload}
                  onSubmit={handleEditPromocodeReq}
                />
              </ModalDiv>
            </Fade>
          </div>
        </Modal>
      </div>
    </PageView>
  );
};

export default AdminPromocodes;

const Inner = styled.div`
  #editIcon {
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
`;

const ModalDiv = styled.div`
  background-color: white;
  padding: 3rem;
  border-radius: 2rem;
`;
