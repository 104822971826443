/* eslint-disable no-restricted-syntax */
/* eslint-disable default-case */
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
  useContext,
} from 'react';
import {
  MdOutlineNotificationImportant,
  MdNotificationsNone,
  MdOutlineSchedule,
  MdClose,
  MdOutlineFactCheck,
} from 'react-icons/md';
import {
  LuGoal,
  LuReceipt,
  LuBarChartBig,
  LuEye,
  LuLayoutDashboard,
  LuHelpCircle,
  LuSiren,
} from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

import ConsumptionImg from '../../../assets/images/icons/monochromatic-png/evolucao de consumo 1_1.png';
import HomeImg from '../../../assets/images/icons/positive-png/inicio 3.png';
import MyInvoicesImg from '../../../assets/images/icons/monochromatic-png/faturas 1.png';
import ImpactImg from '../../../assets/images/icons/monochromatic-png/impacto 1.png';
import RecomendationsImg from '../../../assets/images/icons/monochromatic-png/recomendacoes 3.png';
import PendenciesImg from '../../../assets/images/icons/monochromatic-png/pendencias 1.png';
import NotificationImg from '../../../assets/images/icons/monochromatic-png/pendencias 2.png';
import StatusImg from '../../../assets/images/icons/monochromatic-png/sinal de correto.png';

import { NewPageView } from '../../../containers/NewPageView';
import { LoadingCustomModal } from '../../../components/Modals/LoadingCustomModal';
import { Dropdown } from '../../../components/Dropdown';
import { DropdownCard } from '../../../components/DropdownCard';
import { PrimaryButton } from '../../../components/PrimaryButton';

import {
  BodyNotificationContainer,
  ButtonsContainer,
  Card,
  CardsContainer,
  CardTitle,
  CardWithDropdownContainer,
  CloseButton,
  Container,
  Content,
  ContinueHiringContainer,
  CreditStockButton,
  CreditStockContainer,
  FooterCard,
  HasPendenciesOrNotificationContainer,
  Header,
  IconContainer,
  InvoicesAndConsumptionContainer,
  NotificationButton,
  NotificationContainer,
  SearchButton,
  Separator,
  TableContainer,
  Title,
  TitleWithDropdownContainer,
  TitleWithDropdownContent,
  Tooltip,
  TooltipContainer,
  UnderstandYourBillButton,
  UploadContainer,
} from './styles';

import { StepProgressBar } from '../../../components/StepProgressBar';
import { formatNumberWithoutCents } from '../../../utils/ formatNumberWithoutCents';
import {
  TotalValueInvoiceSelected,
  formatCurrency,
  resumeOptionsMonths,
} from './helpers';
import { UnderstandYourBillModal } from '../../../components/Modals/UnderstandYourBillModal';
import { Graphic } from '../../../components/Graphic';
import { useAuthStore } from '../../../store';
import { Impact } from '../../../components/Impact';
import { useRefSize } from '../../../hooks/resizeListener';
import { UserContext } from '../../../contexts/userContext';
import { getRegisterButton } from '../../../helpers/pendencies';
import { compareObject } from '../../../helpers/object';
import { UploadCustomPendencies } from '../../../components/UploadCustomPendencies';
import { ImproveExperienceModal } from '../../../components/Modals/ImproveExperienceModal';
import { ThemeContext } from '../../../contexts/themeContext';

export const NewHome = () => {
  const {
    pendencies,
    setPendencies,
    globalPendencies,
    recommendations,
    handlePendencies,
    propertiesData,
    loading,
    hasCustomerResearchPendency,
  } = useContext(UserContext);

  const { themeValue } = useContext(ThemeContext);
  const elementRef = useRef(null);
  const [invoicesData, setInvoicesData] = useState([]);
  const [invoiceSelected, setInvoiceSelected] = useState({});
  const [consumptionSelected, setConsumptionSelected] = useState('real');
  const [impactSelected, setImpactSelected] = useState({});
  const [addressSelected, setAddressSelected] = useState({});
  const [addressOptions, setAddressOptions] = useState([]);
  const [preparedInvoices, setPreparedInvoices] = useState([]);
  const [impactOptions, setImpactOptions] = useState([]);
  const [showBtnUnderstandYourBill, setShowBtnUnderstandYourBill] =
    useState(false);
  const [openUnderstandYourBillModal, setOpenUnderstandYourBillModal] =
    useState(false);
  const [openImproveExperienceModal, setOpenImproveExperienceModal] =
    useState(false);
  const [showCreditStock, setShowCreditStock] = useState(false);
  const [textForPendingActivation, setTextForPendingActivation] =
    useState(false);
  const [modalAlreadyClosed] = useState(() => {
    return JSON.parse(
      localStorage.getItem('@FloraEnergia:closedImproveYourExperience')
    );
  });
  const { size, setSize } = useRefSize(elementRef);
  const maxWidthGraphicMobile = 380;
  const isDeskTop = useMemo(() => size > 800, [size]);
  const onRefChange = useCallback((node) => {
    elementRef.current = node;
    setSize(elementRef?.current?.clientWidth);
  }, []);
  const user = useAuthStore((state) => state.userAuth);

  const navigate = useNavigate();

  const getTextForPendingActiovation = useCallback((addressSelected) => {
    const onHoldText =
      'Ainda não atendemos sua região, mas isso deve mudar em breve!';
    const targetText =
      'Agora é com a gente! Estamos em processo de ativação do seu benefício junto à sua distribuidora.';

    try {
      const { profile } = addressSelected;
      setTextForPendingActivation(
        profile === 'on_hold' ? onHoldText : targetText
      );
    } catch (error) {
      setTextForPendingActivation(targetText);
    }
  }, []);

  const mountGraphicData = useCallback((invoices, invoicesPerGraph) => {
    try {
      const initiaData = [];
      const finalData = [];

      const setTopLabel = (targetData, percentToTest = 10) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const { economy, invoiceWithCompany } of targetData)
          if (economy / (economy + invoiceWithCompany) < percentToTest / 100)
            return true;

        return false;
      };

      // eslint-disable-next-line no-restricted-syntax
      for (const invoice of invoices) {
        const {
          producer_total_cents,
          savings,
          energyProvider,
          compensated,
          captive_consumption,
          calc_reduced_cents,
          value_cents,
        } = invoice;
        const invoiceWithoutCompanyCalc = (
          Math.ceil((producer_total_cents ?? 0) / 100) +
          Math.ceil((calc_reduced_cents ?? 0) / 100)
        ).toFixed(0);
        const invoiceWithCompanyValue = (
          Math.ceil((producer_total_cents ?? 0) / 100) +
          Math.ceil((value_cents ?? 0) / 100)
        ).toFixed(0);

        let { label } = invoice;

        label = label.replace(' ', '').split('-').join('').toUpperCase();

        const payload = {
          invoiceWithoutCompany: Number(invoiceWithoutCompanyCalc),
          invoiceWithCompany: Number(invoiceWithCompanyValue),
          economy: savings,
          energyCompany: +compensated,
          energyDistributor: energyProvider,
          total: captive_consumption ? +(+captive_consumption).toFixed(0) : 0,
          label,
        };
        initiaData.push(payload);
      }

      for (let i = 0; i < invoices.length; i += 1) {
        const targetData = initiaData.slice(i, i + invoicesPerGraph);

        if (targetData.length < invoicesPerGraph && i !== 0) break;
        const isTopLabel = setTopLabel(targetData);
        const payload = { invoices: targetData, isTopLabel };
        finalData.push(payload);
      }

      return finalData;
    } catch (error) {
      console.log('file: index.js || line 125 || error', error);
      throw error;
    }
  }, []);

  const { invoices, status, promocode } = addressSelected;

  const insumesDashboard = useCallback(() => {
    const invoicesForGraphic = invoices?.map((item) => ({
      ...item,
      savings: item.economy_cents / 100,
      label: resumeOptionsMonths(item.label),
    }));

    const readyData = mountGraphicData(
      invoicesForGraphic,
      size <= maxWidthGraphicMobile ? 2 : 3
    );

    setPreparedInvoices(readyData);

    setInvoicesData(invoicesForGraphic.reverse());
  }, [mountGraphicData, invoices, size]);

  useEffect(() => {
    if (invoices?.length) {
      setInvoiceSelected(invoices[invoices.length - 1]);

      insumesDashboard();
    }
  }, [invoices, insumesDashboard]);

  const objectMOCK = [
    {
      value: 1,
      circleValue: 1,
      labelText: 'Cadastrado',
    },
    {
      value: 2,
      circleValue: 2,
      labelText: 'Aguardando ativação',
    },
    {
      value: 3,
      circleValue: 3,
      labelText: 'Ativo',
    },
  ];

  const consumptionOptions = [
    { label: '(R$)', value: 'real' },
    { label: '(kWh)', value: 'kwh' },
  ];

  const statusContract = {
    draft: 1,
    pending_signature: 1,
    pending_activation: 2,
    active: 3,
  };

  const handleAddressSelected = (addressSelectedData) => {
    if (!(addressOptions && addressSelectedData)) return;
    const { key } = addressSelectedData;
    const propertySelected = propertiesData.find(
      ({ location_id }) => location_id === key
    );

    if (!propertySelected) return;
    const isSameLocation = compareObject(addressSelected, propertySelected);
    if (isSameLocation) return;

    setPendencies([]);
    setAddressSelected(propertySelected);
  };

  const getAllAddressOptions = useCallback((definedPropertiesData) => {
    const finalData = [];

    for (const index in definedPropertiesData) {
      const { location_id, nickname } = definedPropertiesData[index];
      finalData.push({
        label: nickname || `Imóvel ${index}`,
        key: location_id,
      });
    }
    return finalData;
  }, []);

  const getAllImpactOptions = useCallback((addressSelected) => {
    const finalData = [];
    if (!addressSelected) return;
    const { impact, promocode } = addressSelected;
    if (impact) finalData.push({ label: 'Pessoal', value: 'personal' });
    if (promocode) finalData.push({ label: 'Empresarial', value: 'business' });

    return finalData;
  }, []);

  const searchFirstPropertyWithRecommendation = (property) => {
    return property.requestProviderData;
  };

  // useEffect(() => {
  //   if (!propertiesData) return;
  //   const active = propertiesData.find(({ status }) => status === 'active');
  //   if (!active) return;
  //   handleAddressSelected({ key: active.location_id });
  // }, [addressOptions]);

  useEffect(() => {
    if (!propertiesData) return;
    const addressesForDropDownlist = getAllAddressOptions(propertiesData);
    setAddressOptions(addressesForDropDownlist);
  }, [propertiesData]);

  const verifyShowButtonUnderstantYourBill = (
    invoiceSelected,
    providerCode
  ) => {
    const {
      brute_tax,
      compensated,
      uncompensated_tax_cents,
      calc_reduced_cents,
      captive_consumption,
    } = invoiceSelected;

    let show;
    switch (providerCode) {
      case 'cpfl_paulista':
      case 'cpfl_santa_cruz':
      case 'cpfl_piratininga':
      case 'enel_rj':
      case 'enel_sp':
        if (calc_reduced_cents && compensated && captive_consumption)
          show = true;
        break;
      case 'cemig':
      case 'light':
        if (brute_tax && compensated && uncompensated_tax_cents) show = true;
        break;
      default:
        show = false;
        break;
    }

    setShowBtnUnderstandYourBill(show);
  };

  useEffect(() => {
    //enable button "Entenda sua fatura"
    if (Object.keys(addressSelected).length > 0)
      verifyShowButtonUnderstantYourBill(
        invoiceSelected,
        addressSelected.provider_code
      );
  }, [invoiceSelected]);

  useEffect(() => {
    if (!addressSelected) return;
    getTextForPendingActiovation(addressSelected);
    handlePendencies(addressSelected);
    const availableImpactOptions = getAllImpactOptions(addressSelected);
    if (!availableImpactOptions) setImpactOptions([]);
    else setImpactOptions(availableImpactOptions);
  }, [addressSelected]);

  useEffect(() => {
    if (!propertiesData.length) return;

    const { requestProviderData } =
      propertiesData?.find(searchFirstPropertyWithRecommendation) || {};

    if (requestProviderData && !modalAlreadyClosed) {
      setOpenImproveExperienceModal(true);
    }
  }, [propertiesData, modalAlreadyClosed]);

  const handleConsumption = useCallback((consumptionSelectedData) => {
    setConsumptionSelected(consumptionSelectedData.value);
  }, []);

  const handleInvoiceSelected = useCallback((invoiceSelectedData) => {
    setInvoiceSelected(invoiceSelectedData);
  }, []);

  const handleImpactSelected = useCallback((impactSelectedData) => {
    setImpactSelected(impactSelectedData);
  }, []);

  const handlePendingNotification = () => {
    navigate({ pathname: '/pendencias', state: globalPendencies });
  };

  const handleRecommendations = () => {
    navigate({ pathname: '/recomendacoes' });
  };

  const handleUpdateProfile = () => {
    navigate('/perfil');
  };

  const handleNewProperty = () => {
    navigate('/imoveis/novo');
  };

  const handleOpenUnderstandYourBillModal = () => {
    setOpenUnderstandYourBillModal(true);
  };

  const handleCloseUnderstandYourBillModal = () => {
    setOpenUnderstandYourBillModal(false);
  };

  const handleCloseImproveExperienceModal = useCallback(() => {
    setOpenImproveExperienceModal(false);
  }, []);

  const handleShowCreditStockTooltip = () => {
    setShowCreditStock(true);
  };

  const handleCloseCreditStockTooltip = () => {
    setShowCreditStock(false);
  };

  const handleContinueHiring = () => {
    // navigate('/');
  };
  const isRegisterPendency = useCallback((pendencies) => {
    const allowedStages = [0, 1, 2, 3, 4, 5];
    if (!pendencies?.length) return false;
    for (const pendencyData of pendencies) {
      if (typeof pendencyData === 'string') continue;
      const { id, pendency, stage, status } = pendencyData;

      if (!allowedStages.includes(stage)) continue;
      if (pendency === 'register') return { id, stage, status };
    }
    return false;
  }, []);

  const setTextForResume = useCallback((stageFromPendency) => {
    try {
      if (!stageFromPendency) return;
      const { stage, status } = stageFromPendency;
      if (status && status !== 'draft') return 'etapa 3 - Assinatura';

      switch (stage) {
        case 0:
          return 'etapa 1 - Seu Imóvel';
        case 1:
          return 'etapa 2 - Proposta';
        case 2:
          return 'etapa 3 - Assinatura';
        default:
          return 'etapa 1 - Seu Imóvel';
      }
    } catch (error) {
      return 'etapa 1 - Seu Imóvel';
    }
  }, []);

  const registerPendency = isRegisterPendency(pendencies);

  return (
    <NewPageView>
      <LoadingCustomModal isLoading={loading} />
      {openUnderstandYourBillModal && (
        <UnderstandYourBillModal
          data={invoiceSelected}
          name={user?.name}
          providerCode={addressSelected.provider_code}
          isOpen={openUnderstandYourBillModal}
          onRequestClose={handleCloseUnderstandYourBillModal}
        />
      )}
      <ImproveExperienceModal
        isOpen={openImproveExperienceModal}
        onRequestClose={handleCloseImproveExperienceModal}
        id={propertiesData.find(searchFirstPropertyWithRecommendation)?.id}
      />

      <Container themeValue={themeValue}>
        <Content>
          <TitleWithDropdownContainer>
            <TitleWithDropdownContent>
              <img
                src={HomeImg}
                style={{ width: '3.5rem', height: '3.5rem' }}
              />
              <Title>
                <h2>
                  Olá <strong>{user?.name}</strong>
                </h2>
                <h2>Bem-vindo(a)!</h2>
              </Title>
            </TitleWithDropdownContent>

            <Dropdown
              title='Imóvel'
              options={addressOptions}
              onChange={handleAddressSelected}
              selected
              themeValue={themeValue}
              styles={{
                label: { fontSize: '16px', fontWeight: 600 },
                value: { fontSize: 18 },
                toggleButton: { paddingTop: 24 },
              }}
            />
          </TitleWithDropdownContainer>

          <Separator />

          <NotificationContainer>
            {!!globalPendencies?.length && (
              <NotificationButton
                onClick={handlePendingNotification}
                isPendency
              >
                <img
                  src={PendenciesImg}
                  style={{ width: '1.5rem', height: '1.5rem' }}
                />
                <h2>Pendências</h2>
              </NotificationButton>
            )}

            {!!recommendations?.length && modalAlreadyClosed && !loading && (
              <NotificationButton onClick={handleRecommendations}>
                <img
                  src={RecomendationsImg}
                  style={{ width: '1.5rem', height: '1.5rem' }}
                />
                <h2>Recomendações</h2>
              </NotificationButton>
            )}
          </NotificationContainer>

          <CardsContainer>
            {!invoices?.length &&
              (status === 'draft' ||
                status === 'pending_signature' ||
                status === 'pending_activation' ||
                status === 'active') && (
                <HasPendenciesOrNotificationContainer>
                  <Card>
                    <CardTitle>
                      <img
                        src={StatusImg}
                        style={{ width: '1.5rem', height: '1.5rem' }}
                      />
                      <h2>Status</h2>
                    </CardTitle>

                    <StepProgressBar
                      steps={objectMOCK}
                      stageActive={statusContract[status]}
                    />
                  </Card>

                  {registerPendency && (
                    <Card>
                      <CardTitle>
                        <img
                          src={PendenciesImg}
                          style={{ width: '1.5rem', height: '1.5rem' }}
                        />
                        <h2>Pendências</h2>
                      </CardTitle>
                      <ContinueHiringContainer>
                        <p>
                          Você parou na{' '}
                          <strong>{setTextForResume(registerPendency)}</strong>,
                          clique no botão abaixo para dar continuidade a
                          contratação.
                        </p>

                        {getRegisterButton(registerPendency, navigate)}
                      </ContinueHiringContainer>
                    </Card>
                  )}

                  {pendencies.includes('attachment') && (
                    <Card>
                      <CardTitle isPendency>
                        <LuSiren
                          size='1.5rem'
                          color='#B3261E'
                        />
                        <h2>Pendências</h2>
                      </CardTitle>

                      <div>
                        <UploadContainer>
                          <p>
                            Não esqueça de anexar sua conta de luz! Só assim
                            conseguiremos ativar seu benefício junto à sua
                            distribuidora.
                          </p>

                          <UploadCustomPendencies
                            location_id={addressSelected?.location_id}
                            accept='application/pdf'
                            className='primary'
                            resetAfterUpload
                          />
                        </UploadContainer>
                      </div>
                    </Card>
                  )}
                  {(pendencies.includes('newProfileAndNewLocation') ||
                    pendencies.includes('on_hold')) && (
                    <Card>
                      <CardTitle>
                        <img
                          src={NotificationImg}
                          style={{ width: '1.5rem', height: '1.5rem' }}
                        />
                        <h2>Notificação</h2>
                      </CardTitle>

                      <BodyNotificationContainer>
                        {hasCustomerResearchPendency ? (
                          <>
                            <div>
                              <p>{textForPendingActivation}</p>
                              <p>
                                Enquanto isso, aproveite para atualizar seu
                                perfil e/ou adicione um novo imóvel!
                              </p>
                            </div>
                            <ButtonsContainer>
                              <PrimaryButton onClick={handleUpdateProfile}>
                                Atualizar perfil
                              </PrimaryButton>
                              <PrimaryButton onClick={handleNewProperty}>
                                Novo imóvel
                              </PrimaryButton>
                            </ButtonsContainer>
                          </>
                        ) : (
                          <>
                            <div>
                              <p>{textForPendingActivation}</p>
                              <p>
                                Enquanto isso, aproveite para adicionar um novo
                                imóvel!
                              </p>
                            </div>
                            <ButtonsContainer>
                              <PrimaryButton onClick={handleNewProperty}>
                                Novo imóvel
                              </PrimaryButton>
                            </ButtonsContainer>
                          </>
                        )}
                      </BodyNotificationContainer>
                    </Card>
                  )}
                  {pendencies.includes('waitInvoice') && (
                    <Card>
                      <CardTitle>
                        <img
                          src={NotificationImg}
                          style={{ width: '2rem', height: '2rem' }}
                        />
                        <h2>Notificação</h2>
                      </CardTitle>

                      <BodyNotificationContainer>
                        <p>
                          Aguarde sua conta de luz para ver seus benefícios.
                        </p>
                      </BodyNotificationContainer>
                    </Card>
                  )}
                </HasPendenciesOrNotificationContainer>
              )}

            {!!invoices?.length && (
              <>
                <InvoicesAndConsumptionContainer
                  ref={onRefChange}
                  style={
                    isDeskTop
                      ? {
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }
                      : { flexDirection: 'column' }
                  }
                >
                  <Card
                    style={isDeskTop ? { width: '49%' } : { width: '100%' }}
                  >
                    <CardWithDropdownContainer>
                      <CardTitle>
                        <img
                          src={MyInvoicesImg}
                          style={{ width: '1.5rem', height: '1.5rem' }}
                        />
                        <h2>Minhas faturas</h2>
                      </CardTitle>

                      <DropdownCard
                        options={invoicesData}
                        onChange={handleInvoiceSelected}
                        selected
                      />
                    </CardWithDropdownContainer>
                    <TableContainer>
                      <thead>
                        <tr>
                          <th />
                          <th>Valor (R$)</th>
                          <th>Consumo (kWh)</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Flora</strong>
                          </td>
                          <td>
                            {formatCurrency(
                              Math.ceil(invoiceSelected?.value_cents / 100),
                              true,
                              true
                            )}
                          </td>
                          <td>
                            {formatNumberWithoutCents(
                              Math.ceil(invoiceSelected?.compensated)
                            )}
                          </td>
                          <td>
                            <SearchButton
                              href={invoiceSelected?.secure_url}
                              target='_blank'
                            >
                              <LuEye size='1rem' />
                            </SearchButton>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Distribuidora</strong>
                          </td>
                          <td>
                            {formatCurrency(
                              Math.ceil(
                                invoiceSelected?.producer_total_cents / 100
                              ),
                              true,
                              true
                            )}
                          </td>
                          <td>
                            {formatNumberWithoutCents(
                              Math.ceil(invoiceSelected?.energyProvider)
                            )}
                          </td>
                          <td>
                            <SearchButton
                              href={invoiceSelected?.attachment_link}
                              target='_blank'
                            >
                              <LuEye size='1rem' />
                            </SearchButton>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Total</td>
                          <td>
                            {TotalValueInvoiceSelected(
                              Math.ceil(invoiceSelected?.value_cents / 100),
                              Math.ceil(
                                invoiceSelected?.producer_total_cents / 100
                              )
                            )}
                          </td>
                          <td>
                            {formatNumberWithoutCents(
                              Math.ceil(invoiceSelected?.captive_consumption)
                            )}
                          </td>
                        </tr>
                      </tfoot>
                    </TableContainer>
                    <FooterCard
                      showBtnUnderstandYourBill={showBtnUnderstandYourBill}
                    >
                      <CreditStockContainer>
                        <div>
                          <span>{`Estoque de créditos: ${formatCurrency(
                            invoiceSelected?.balanceCents
                          )}`}</span>
                        </div>

                        <div style={{ position: 'relative' }}>
                          {showCreditStock && (
                            <TooltipContainer>
                              <Tooltip>
                                <Header>
                                  <h6>O que é estoque de crédito?</h6>
                                  <CloseButton
                                    onClick={handleCloseCreditStockTooltip}
                                  >
                                    <MdClose size='1.5rem' />
                                  </CloseButton>
                                </Header>
                                <p>
                                  Caso você consuma menos energia do que a Flora
                                  alocou para sua conta, esse excedente ficará
                                  como saldo de crédito para você, podendo ser
                                  consumido nos próximos meses.
                                </p>
                              </Tooltip>
                            </TooltipContainer>
                          )}

                          <CreditStockButton
                            onClick={handleShowCreditStockTooltip}
                          >
                            <LuHelpCircle size='1.5rem' />
                          </CreditStockButton>
                        </div>
                      </CreditStockContainer>

                      <div />

                      {showBtnUnderstandYourBill && (
                        <UnderstandYourBillButton
                          onClick={handleOpenUnderstandYourBillModal}
                        >
                          Entenda sua fatura
                        </UnderstandYourBillButton>
                      )}
                    </FooterCard>
                  </Card>

                  <Card
                    style={
                      isDeskTop
                        ? {
                            width: '49%',
                            maxWidth: '528px',
                          }
                        : { width: '100%' }
                    }
                  >
                    <CardWithDropdownContainer>
                      <CardTitle>
                        <img
                          src={ConsumptionImg}
                          style={{ width: '1.5rem', height: '1.5rem' }}
                        />
                        <h2>Evolução de consumo</h2>
                      </CardTitle>
                      <DropdownCard
                        options={consumptionOptions}
                        onChange={handleConsumption}
                        selected={consumptionSelected}
                      />
                    </CardWithDropdownContainer>

                    <Graphic
                      data={preparedInvoices}
                      type={consumptionSelected}
                    />
                  </Card>
                </InvoicesAndConsumptionContainer>

                <Card>
                  <CardWithDropdownContainer>
                    <CardTitle>
                      <img
                        src={ImpactImg}
                        style={{ width: '1.5rem', height: '1.5rem' }}
                      />
                      <h2>Impacto</h2>
                    </CardTitle>

                    {!!impactOptions && (
                      <DropdownCard
                        options={impactOptions}
                        onChange={handleImpactSelected}
                        selected
                      />
                    )}
                  </CardWithDropdownContainer>
                  <Impact
                    addressSelected={addressSelected}
                    impactSelected={impactSelected}
                    invoiceSelected={invoiceSelected}
                  />
                </Card>
              </>
            )}
          </CardsContainer>
        </Content>
      </Container>
    </NewPageView>
  );
};
