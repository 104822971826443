import React, { useEffect, useState } from 'react';
import { Form } from 'formik';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import GenericForm from '../../Forms/GenericForm';
import {
  FieldWrapper,
  Label,
  CustomField,
  CustomErrorMessage,
} from '../../Interface/Form';
import UpdateCompanyFormSchema from './schema';
import { FormPropTypes, FormDefaultProps } from '../../Forms/types';
import { formMasks } from '../../../helpers/form/masks';
import { useAuthStore } from '../../../store';
import { USER_ROLES_TYPES } from '../../../helpers/userRoles';
import { CONTRACT_STATUS_TYPES_PTBR } from '../../../helpers/contractStatusTypes';
import GradientButton from '../../GradientButton';
import { useAdminContractDetailsStore } from '../../../store/admin/contractDetails.store';
import SignerFormModal from './SignerFormModal';
import ConfirmModal from '../../Modals/ConfirmModal/index';
import theme from '../../../theme';

const initialValues = {};

const UpdateCompanyForm = ({
  values,
  onSubmit,
  onCancel,
  readonly,
  shouldCreateContract,
}) => {
  const { contract } = useAdminContractDetailsStore();
  const role = useAuthStore((state) => state.userAuth?.role);
  const [signer, setSigner] = useState(values.signer);

  const [isCustomer, setIsCustomer] = useState(false);
  useEffect(() => {
    setIsCustomer(role === USER_ROLES_TYPES.Customer);
  }, [role]);

  const [isOpenSignerFormModal, setIsOpenSignerFormModal] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const isAdmin = role === USER_ROLES_TYPES.Admin;

  const handleCancel = (resetForm) => {
    resetForm();
    onCancel();
  };

  const getContractStatus = (contract) => {
    return CONTRACT_STATUS_TYPES_PTBR[contract.status]?.toUpperCase();
  };

  const defineSigner = (data) => {
    setSigner(data);
    setIsOpenSignerFormModal(false);
  };

  const submitUpdateCompany = (data, { setSubmitting }) => {
    Object.assign(data, { signer });
    onSubmit({ ...data }, { setSubmitting });
    setIsConfirmModalOpen(false);
  };

  return (
    <>
      <GenericForm
        enableReinitialize
        initialValues={initialValues}
        values={values}
        schema={UpdateCompanyFormSchema}
        onSubmit={submitUpdateCompany}
      >
        {({ handleSubmit, isSubmitting, resetForm }) => {
          return (
            <Form
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              {!isAdmin && (
                <FieldWrapper>
                  <Label htmlFor='holder'>
                    Status: <Spann>{getContractStatus(contract)}</Spann>
                  </Label>
                </FieldWrapper>
              )}

              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  lg={6}
                >
                  <FieldWrapper>
                    <Label htmlFor='holder'>Nome/Razão Social:</Label>
                    <CustomField
                      name='holder'
                      disabled={readonly && isAdmin}
                      // placeholder='Estamos extraindo esse dado da conta de luz :)'
                      style={{
                        color: 'black',
                        margin: '0',
                      }}
                      fullwidth={1}
                    />
                    <CustomErrorMessage name='holder' />
                  </FieldWrapper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                >
                  <FieldWrapper>
                    <Label htmlFor='document'>CPF/CNPJ:</Label>
                    <CustomField
                      name='document'
                      mask={formMasks.cpfOrCnpj}
                      disabled={readonly && isAdmin}
                      style={{
                        color: 'black',
                        margin: '0',
                      }}
                      // placeholder='Estamos extraindo esse dado da conta de luz :)'
                      fullwidth={1}
                    />
                    <CustomErrorMessage name='document' />
                  </FieldWrapper>
                </Grid>

                <>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                  >
                    <FieldWrapper>
                      <Label htmlFor='street'>Rua</Label>
                      <CustomField
                        name='street'
                        disabled={
                          (readonly || !shouldCreateContract) && isAdmin
                        }
                        style={{
                          color: 'black',
                          margin: '0',
                        }}
                        fullwidth={1}
                      />
                      <CustomErrorMessage name='street' />
                    </FieldWrapper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                  >
                    <FieldWrapper>
                      <Label htmlFor='number'>Número</Label>
                      <CustomField
                        name='number'
                        type='number'
                        disabled={
                          (readonly || !shouldCreateContract) && isAdmin
                        }
                        style={{
                          color: 'black',
                          margin: '0',
                        }}
                        fullwidth={1}
                      />
                      <CustomErrorMessage name='number' />
                    </FieldWrapper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                  >
                    <FieldWrapper
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Label htmlFor='complement'>Complemento</Label>
                      <CustomField
                        name='complement'
                        disabled={
                          (readonly || !shouldCreateContract) && isAdmin
                        }
                        style={{
                          color: 'black',
                          margin: '0',
                        }}
                        fullwidth={1}
                      />
                      <CustomErrorMessage name='complement' />
                    </FieldWrapper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                  >
                    <FieldWrapper
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Label htmlFor='postal_code'>CEP</Label>
                      <CustomField
                        name='postal_code'
                        type='number'
                        disabled={
                          (readonly || !shouldCreateContract) && isAdmin
                        }
                        style={{
                          color: 'black',
                          margin: '0',
                        }}
                        fullwidth={1}
                      />
                      <CustomErrorMessage name='postal_code' />
                    </FieldWrapper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                  >
                    <FieldWrapper
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Label htmlFor='neighborhood'>Bairro</Label>
                      <CustomField
                        name='neighborhood'
                        disabled={
                          (readonly || !shouldCreateContract) && isAdmin
                        }
                        style={{
                          color: 'black',
                          margin: '0',
                        }}
                        fullwidth={1}
                      />
                      <CustomErrorMessage name='neighborhood' />
                    </FieldWrapper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                  >
                    <FieldWrapper
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Label htmlFor='city'>Cidade</Label>
                      <CustomField
                        name='city'
                        disabled={
                          (readonly || !shouldCreateContract) && isAdmin
                        }
                        style={{
                          color: 'black',
                          margin: '0',
                        }}
                        fullwidth={1}
                      />
                      <CustomErrorMessage name='city' />
                    </FieldWrapper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                  >
                    <FieldWrapper
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Label htmlFor='state'>Estado</Label>
                      <CustomField
                        name='state'
                        disabled={
                          (readonly || !shouldCreateContract) && isAdmin
                        }
                        style={{
                          color: 'black',
                          margin: '0',
                        }}
                        fullwidth={1}
                      />
                      <CustomErrorMessage name='state' />
                    </FieldWrapper>
                  </Grid>
                </>
              </Grid>

              {!isCustomer && (
                <BtnsCont>
                  {!readonly && onCancel && (
                    <GradientButton
                      background='white'
                      color={theme.palette.primary.main}
                      border={`1px solid ${theme.palette.primary.main}`}
                      text='Cancelar'
                      paddingg={'10px 30px'}
                      handleClick={() => handleCancel(resetForm)}
                      disabled={isSubmitting}
                      outlined
                      rectangle
                    />
                  )}
                  {!readonly && shouldCreateContract && (
                    <GradientButton
                      background='white'
                      color={theme.palette.primary.main}
                      border={`1px solid ${theme.palette.primary.main}`}
                      text='Definir signatário'
                      paddingg={'10px 30px'}
                      handleClick={() => setIsOpenSignerFormModal(true)}
                      disabled={isSubmitting}
                      outlined
                      rectangle
                      marginLeft={24}
                    />
                  )}
                  {!readonly && (
                    <GradientButton
                      paddingg={'10px 30px'}
                      text='Salvar'
                      handleClick={
                        shouldCreateContract
                          ? () => setIsConfirmModalOpen(true)
                          : handleSubmit
                      }
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                      rectangle
                      isSubmitting={isSubmitting}
                      marginLeft={24}
                    />
                  )}
                </BtnsCont>
              )}
              {isConfirmModalOpen && (
                <ConfirmModal
                  open={isConfirmModalOpen}
                  onClose={() => setIsConfirmModalOpen(false)}
                  onConfirm={handleSubmit}
                  isLoading={isSubmitting}
                  title='Essa ação irá enviar um email automático para o cliente!'
                />
              )}
            </Form>
          );
        }}
      </GenericForm>
      {isOpenSignerFormModal && (
        <SignerFormModal
          isOpen={isOpenSignerFormModal}
          setIsOpen={setIsOpenSignerFormModal}
          values={signer}
          handleSubmit={defineSigner}
        />
      )}
    </>
  );
};

UpdateCompanyForm.propTypes = FormPropTypes;
UpdateCompanyForm.defaultProps = FormDefaultProps;

export default UpdateCompanyForm;

const Spann = styled.span`
  color: #f9af40;
`;

const BtnsCont = styled.div`
  display: flex;
`;
