import { InputCustom } from '../../../components/InputCustom';
import {
  validationData,
  validationName,
  validationNumber,
  validationPostalCode,
} from '../../../helpers/form/validations';
import { mask as MASK, unMask as UNMASK } from 'remask';
import { onlyNumbers, onlyString } from '../../../helpers/form/formatter';
import { useEffect, useState } from 'react';
import { Checkbox } from '../../../components/Checkbox';
import CEPService from '../../../services/general/cep.service';
import { stateInformed } from '../../../components/StagesProgress/StageOne/helpers';
import { CardContent, CardTitle } from './styles';
import { SecondaryButton } from '../../../components/SecondaryButton';
import { PrimaryButton } from '../../../components/PrimaryButton';
import { toast } from 'react-toastify';
import { TOAST_MESSAGES } from '../../../helpers/toastMessages';

const masks = {
  postalCode: '99999-999',
};

export const PaymentAddress = ({
  payload,
  setPayload,
  handleSubmitAddress,
}) => {
  const [postalCodeHasChanged, setPostalCodeHasChanged] = useState(false);
  const [disabledPostalCode, setDisabledPostalCode] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [addressData, setAddressData] = useState({
    postal_code: { value: '' },
    city: { value: '', status: 'refused' },
    state: { value: '', status: 'refused' },
    street: { value: '', status: 'refused' },
    neighborhood: { value: '', status: 'refused' },
    number: { value: '', status: 'refused' },
    complement: { value: '', status: 'refused' },
    postal_code_isCorrectly: false,
  });

  useEffect(() => {
    const {
      postal_code,
      city,
      state,
      street,
      neighborhood,
      number,
      complement,
    } = payload;
    setAddressData((prevState) => ({
      ...prevState,
      postal_code,
      city,
      state,
      street,
      neighborhood,
      number,
      complement,
    }));
    setIsEditingAddress(false);
  }, [payload]);

  useEffect(() => {
    const fieldsToValidate = [
      'postal_code',
      'city',
      'state',
      'street',
      'number',
      'neighborhood',
    ];
    const statusFields = [];
    for (const field of fieldsToValidate) {
      const { status } = addressData[field] || {};
      statusFields.push(status);
    }
    const invalid = statusFields.some((status) => status !== 'accepted');
    setIsValidAddress(!invalid);
  }, [addressData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await handleSubmitAddress(event, addressData);
      setIsEditingAddress(false);
    } catch (err) {
      const { data } = err;
      if (data === 'payment method not found') {
        toast.error(
          'Cadastre um meio de pagamento antes de editar o endereço de cobrança.'
        );
      } else toast.err(TOAST_MESSAGES.ERROR_DEFAULT_CLIENT);
    }
  };

  useEffect(() => {
    if (addressData.postal_code?.status)
      setAddressData((prevState) => ({
        ...prevState,
        postal_code_isCorrectly:
          prevState.postal_code?.status === 'accepted' ? true : false,
      }));
  }, [addressData.postal_code]);

  useEffect(() => {
    if (!postalCodeHasChanged) return;

    if (addressData.postal_code?.value?.length >= 9 && postalCodeHasChanged) {
      getAddress(addressData.postal_code.value);
      return;
    }

    setAddressData((prevState) => ({
      ...prevState,
      postal_code: { ...addressData.postal_code, status: 'refused' },
    }));
  }, [addressData.postal_code?.value, postalCodeHasChanged]);

  const handlePostalCodeIsCorrectly = (event) => {
    // event.persist();

    setAddressData((prevState) => ({
      ...prevState,
      postal_code_isCorrectly: event.target.checked,
      city: { ...prevState.city, disabled: false },
      street: { ...prevState.street, disabled: false },
      state: { ...prevState.state, disabled: false },
      neighborhood: { ...prevState.neighborhood, disabled: false },
      postal_code: { ...prevState.postal_code, status: 'accepted' },
    }));
  };

  const handleIsEditingAddress = () => {
    setIsEditingAddress(!isEditingAddress);
  };

  const getAddress = async () => {
    try {
      setDisabledPostalCode(true);
      // eslint-disable-next-line no-shadow
      const data = await CEPService.get(UNMASK(addressData.postal_code.value));

      const { state, city, street, neighborhood } = data;

      setAddressData((prevState) => ({
        ...prevState,
        postal_code: { ...addressData.postal_code, status: 'accepted' },
        state: {
          value: stateInformed(state),
          status: 'accepted',
          disabled: state ? true : false,
        },
        city: {
          value: city,
          status: 'accepted',
          disabled: city ? true : false,
        },
        street: {
          value: street ? street.split('-')[0].trim() : '',
          status: street ? 'accepted' : '',
          disabled: street ? true : false,
        },
        neighborhood: {
          value: neighborhood ? neighborhood : '',
          status: neighborhood ? 'accepted' : '',
          disabled: neighborhood ? true : false,
        },
        number: {
          value: '',
          status: 'refused',
        },
      }));
    } catch (err) {
      setAddressData((prevState) => ({
        ...prevState,
        postal_code: { ...prevState.postal_code, status: 'refused' },
        city: { value: '', status: 'awaiting' },
        state: { value: '', status: 'awaiting' },
        street: { value: '', status: 'awaiting' },
        neighborhood: { value: '', status: 'awaiting' },
        number: { value: '', status: 'awaiting' },
      }));
    } finally {
      setDisabledPostalCode(false);
    }
  };

  const handlePostalCode = (event) => {
    event.persist();
    setPostalCodeHasChanged(true);

    const isPostalCodeValid = validationPostalCode(UNMASK(event.target.value));

    setAddressData((prevState) => ({
      ...prevState,
      postal_code: {
        ...prevState.postal_code,
        value: MASK(event.target.value, masks.postalCode),
        status: isPostalCodeValid ? 'awaiting' : 'refused',
      },
      postal_code_isCorrectly: isPostalCodeValid,
    }));

    if (!isPostalCodeValid) {
      setAddressData((prevState) => ({
        ...prevState,
        city: { ...prevState.city, value: '' },
        state: { ...prevState.state, value: '' },
        street: { ...prevState.street, value: '' },
        neighborhood: { ...prevState.neighborhood, value: '' },
        number: { ...prevState.number, value: '' },
        complement: { ...prevState.complement, value: '' },
      }));
    }
  };

  const handleNumber = (event) => {
    event.persist();
    let status = 'accepted';

    const isNumberValid = validationNumber(event.target.value);

    if (!isNumberValid) {
      status = 'refused';
    }

    setAddressData((prevState) => ({
      ...prevState,
      number: {
        ...prevState.number,
        value: onlyNumbers(event.target.value),
        status,
      },
    }));
  };

  const handleCity = (event) => {
    event.persist();

    let status = 'accepted';
    const isCityValid = validationName(onlyString(event.target.value));

    if (!isCityValid) {
      status = 'refused';
    }

    setAddressData((prevState) => ({
      ...prevState,
      city: {
        ...prevState.city,
        value: onlyString(event.target.value),
        status,
      },
    }));
  };

  const handleState = (event) => {
    event.persist();

    let status = 'accepted';
    const isStateValid = onlyString(event.target.value);

    if (!isStateValid) {
      status = 'refused';
    }

    setAddressData((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        value: onlyString(event.target.value),
        status,
      },
    }));
  };

  const handleStreet = (event) => {
    event.persist();
    let status = 'accepted';
    const isStreetValid = validationName(onlyString(event.target.value));
    if (!isStreetValid) {
      status = 'refused';
    }

    setAddressData((prevState) => ({
      ...prevState,
      street: {
        ...prevState.street,
        value: onlyString(event.target.value),
        status,
      },
    }));
  };

  const handleNeighborhood = (event) => {
    event.persist();
    let status = 'accepted';
    const isNeighborhoodValid = onlyString(event.target.value);
    if (!isNeighborhoodValid) {
      status = 'refused';
    }

    setAddressData((prevState) => ({
      ...prevState,
      neighborhood: {
        ...prevState.neighborhood,
        value: event.target.value,
        status,
      },
    }));
  };

  const handleComplement = (event) => {
    event.persist();
    let status = 'accepted';

    const isComplementValid = validationData(event.target.value);

    if (!isComplementValid) {
      status = 'refused';
    }

    setAddressData((prevState) => ({
      ...prevState,
      complement: {
        ...prevState.complement,
        value: event.target.value,
        status,
      },
    }));
  };

  const blurComplement = () => {
    setAddressData((prevState) => ({
      ...prevState,
      complement: { ...prevState.complement, touched: true },
    }));
  };

  return (
    <>
      <CardContent>
        <CardTitle>
          <span
            style={{
              fontSize: '1.125rem',
            }}
          >
            Endereço de cobrança
          </span>
        </CardTitle>

        {isEditingAddress ? (
          <PrimaryButton
            onClick={handleSubmit}
            disabled={!isValidAddress}
          >
            Salvar
          </PrimaryButton>
        ) : (
          <SecondaryButton onClick={handleIsEditingAddress}>
            Editar
          </SecondaryButton>
        )}
      </CardContent>

      <InputCustom
        type='tel'
        name='postalCode'
        labelText='CEP'
        value={MASK(addressData.postal_code?.value || '', masks.postalCode)}
        onChange={handlePostalCode}
        hasTouched
        hasError={
          addressData.postal_code?.status === 'refused' &&
          !addressData.postal_code.disabled
        }
        errorMessage={
          addressData.postal_code?.value?.length >= 9
            ? 'Não encontramos o CEP'
            : 'CEP inválido'
        }
        message={
          addressData.postal_code?.status === 'awaiting'
            ? 'Aguarde! Buscando CEP...'
            : ''
        }
        requiredMessage='CEP obrigatório'
        maxLength={9}
        disabled={!isEditingAddress || disabledPostalCode}
      />

      {!addressData.postal_code_isCorrectly &&
        addressData.postal_code?.value?.length === 9 &&
        addressData.postal_code?.status === 'refused' && (
          <Checkbox
            checked={addressData.postal_code_isCorrectly}
            onChange={handlePostalCodeIsCorrectly}
            id='cep-is-correctly'
          >
            <label>CEP informado está correto.</label>
          </Checkbox>
        )}

      {addressData.postal_code_isCorrectly && (
        <div
          style={{
            display: 'grid',

            gridTemplateColumns: 'repeat(2, 1fr)',

            gridGap: '1rem',
          }}
        >
          <InputCustom
            type='text'
            name='city'
            labelText='Cidade'
            value={addressData.city?.value || ''}
            hasTouched
            disabled={!addressData.city?.disabled ? false : true}
            onChange={handleCity}
            hasError={!(addressData.city?.status === 'accepted')}
            requiredMessage='Cidade é obrigatório'
          />
          <InputCustom
            type='text'
            name='state'
            labelText='Estado'
            value={addressData.state?.value || ''}
            hasTouched
            disabled={!addressData.state?.disabled ? false : true}
            onChange={handleState}
            hasError={!(addressData.state?.status === 'accepted')}
            requiredMessage='Estado é obrigatório'
          />
          <InputCustom
            type='text'
            name='street'
            labelText='Endereço'
            value={addressData.street?.value || ''}
            hasTouched
            disabled={!addressData?.street?.disabled ? false : true}
            onChange={handleStreet}
            hasError={!(addressData.street?.status === 'accepted')}
            requiredMessage='Endereço é obrigatório'
          />
          <InputCustom
            type='text'
            name='neighborhood'
            labelText='Bairro'
            value={addressData.neighborhood?.value || ''}
            disabled={!addressData?.neighborhood?.disabled ? false : true}
            hasTouched
            onChange={handleNeighborhood}
            hasError={!(addressData.neighborhood?.status === 'accepted')}
            requiredMessage='Bairro é obrigatório'
          />
          <InputCustom
            type='text'
            name='numberText'
            labelText='Número'
            value={addressData.number?.value || ''}
            onChange={handleNumber}
            hasTouched
            hasError={!(addressData.number?.status === 'accepted')}
            requiredMessage='Número é obrigatório'
            disabled={!isEditingAddress}
          />
          <InputCustom
            type='text'
            name='complement'
            labelText='Complemento'
            value={addressData.complement?.value || ''}
            onChange={handleComplement}
            onBlur={blurComplement}
            hasTouched={addressData.complement?.touched}
            disabled={!isEditingAddress}
          />
        </div>
      )}
    </>
  );
};
