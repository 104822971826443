import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import SimbolFloraWhiteGreen from '../../../assets/images/logo/symbol-flora-white-green.svg';
import LogoFullWhiteGreen from '../../../assets/images/logo/logo-flora-white.svg';
import { ThemeContext } from '../../../contexts/themeContext';

const BaseNavFloraLogo = ({ className }) => {
  const { themeValue } = useContext(ThemeContext);
  return (
    <div className={className}>
      <NavLink to='/home'>
        <img
          src={
            themeValue?.logo_link && themeValue?.logo_link !== ''
              ? `${import.meta.env.REACT_APP_AWS_BUCKET_URL}/${
                  themeValue.logo_link
                }`
              : SimbolFloraWhiteGreen
          }
          alt='Logo da Flora'
          className='logo'
        />
        <img
          src={LogoFullWhiteGreen}
          alt='Logo flora'
          className='fullLogo'
        />
      </NavLink>
    </div>
  );
};

BaseNavFloraLogo.propTypes = {
  className: PropTypes.string.isRequired,
};

export const NavFloraLogo = styled(BaseNavFloraLogo)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;

  img {
    width: 40px;
  }

  /* breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1366,
  }, */

  @media screen and (max-width: 991px) {
    display: none;
  }
`;
