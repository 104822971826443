import connection from './connection';

const mountFiltersQuery = (filters) => {
  const mappedFilters = {};

  if (filters) {
    const { userId } = filters;

    if (userId) {
      mappedFilters.users_id_eq = userId;
    }

    return `?q=${JSON.stringify(mappedFilters)}`;
  }

  return '';
};

const getAll = async (page = 0, limit = 500) => {
  const { err, data } = await connection.get(
    `/contracts?page=${page.toString()}&limit=${limit.toString()}`
  );

  if (err) {
    throw err;
  }

  return data;
};

const get = async (id) => {
  const { err, data } = await connection.get(`/contracts/${id}`);

  if (err) {
    throw err;
  }

  return data;
};

const getProperty = async (id) => {
  const { err, data } = await connection.get(`/contracts/${id}/details`);

  if (err) {
    throw err;
  }

  return data;
};

const create = async (payload) => {
  const { err, data } = await connection.post(`/contracts`, {
    contract: payload,
  });

  if (err) {
    throw err;
  }

  return data;
};
const newLocationCheck = async (payload, contractId) => {
  const { err, data } = contractId
    ? await connection.post(`/contracts/${contractId}/newLocation`, {
        contract: payload,
      })
    : await connection.put(`/contracts/newLocation`, {
        contract: payload,
      });

  if (err) {
    throw err;
  }

  return data;
};

const newLocationNew = async (payload, contractId) => {
  const { err, data } = contractId
    ? await connection.patch(`/contracts/${contractId}/newLocationNew`, payload)
    : await connection.post('/contracts/newLocationNew', payload);

  if (err) {
    throw err;
  }

  return data;
};

const update = async (payload) => {
  const { err, data } = await connection.put(`/contracts`, {
    contract: payload,
  });

  if (err) {
    throw err;
  }

  return data;
};

const updateStatus = async (payload) => {
  const { err, data } = await connection.put(`/contracts/status`, {
    contract: payload,
  });

  if (err) {
    throw err;
  }

  return data;
};

const sign = async (payload, dontChangeStatus = false) => {
  if (payload?.provider === 'light_2') payload.provider = 'light';
  const contractPayload = {
    contract: payload,
  };
  if (dontChangeStatus) Object.assign(contractPayload, { dontChangeStatus });
  const { err, data } = await connection.put(
    `/contracts/sign`,
    contractPayload
  );

  if (err) {
    throw err;
  }

  return data;
};

const requestSignature = async (id) => {
  const { err, data } = await connection.post(`/contracts/${id}/sign`);

  if (err) {
    throw err;
  }

  return data;
};

const activate = async (id) => {
  const { err, data } = await connection.post(`/contracts/${id}/activate`);

  if (err) {
    throw err;
  }

  return data;
};

const suspend = async (id) => {
  const { err, data } = await connection.post(`/contracts/${id}/suspend`);

  if (err) {
    throw err;
  }

  return data;
};

const isSigned = async (contractId) => {
  const { err, data, status } = await connection.post(
    `/contracts/${contractId}/is_signed`
  );

  const final = { data, status };

  if (err) {
    throw err;
  }

  return final;
};

const sendEmailSignatureRequest = async (payload) => {
  const { err, data } = await connection.post(
    `/contracts/${payload.id}/requestContractNotification`,
    payload
  );

  if (err) {
    throw err;
  }

  return data || '';
};

const ContractsService = {
  getAll,
  get,
  getProperty,
  create,
  update,
  requestSignature,
  activate,
  suspend,
  isSigned,
  sign,
  newLocationCheck,
  sendEmailSignatureRequest,
  updateStatus,
  newLocationNew,
};

export default ContractsService;
