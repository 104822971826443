import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import styled from 'styled-components';
import PageView from '../../../containers/PageView';
import SearchableTable from '../../../components/Interface/SearchableTable';
import UsersService from '../../../services/general/users.service';
import UserForm from '../../../components/Forms/UserForm';
import { generatePayloadFieldsModifieds } from '../../../helpers/form/generatePayloadFieldsModifieds';

import { COLUMNS, getRows } from './helpers';

import { TOAST_MESSAGES } from '../../../helpers/toastMessages';
import { findProviderProfile } from '../../../utils/citiesHelper';

// Helpers
import GradientButton from '../../../components/GradientButton';
import { isntAdmin, useAuthStore } from '../../../store';
import theme from '../../../theme';
import { Heading2 } from '../../../components/Interface/Text';

const AdminUserDetails = () => {
  const navigate = useNavigate();
  const isCustomer = isntAdmin();
  const [isEditing, setIsEditing] = useState(false);
  const { userId } = useParams();
  const [loader, setLoader] = useState();
  const requestId = useAuthStore((state) => state.userAuth?.id);

  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    user: null,
    contracts: [],
    contractPayload: {
      holder: '',
      phone_number: '',
      document: ' ',
    },
    contractSuccess: false,
    isAddingLocation: true,
    locations: [],
    deletingRows: {
      locations: [],
    },
    formStep: 0,
  });

  const { loading, hasError, user, contracts } = pageState;

  const handleDelete = async () => {
    try {
      let req;
      setLoader(true);
      if (userId) {
        req = await UsersService.deleteById({ userId, id: requestId });
        console.log(req);
      }

      if (req?.done) {
        toast.success(TOAST_MESSAGES.SUCCESS);
        navigate(`/contratos`);
        return;
      } else {
        setLoader(false);
        toast.error(TOAST_MESSAGES.ERROR);
        throw { error: true };
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      if (error.status === 404) {
        toast.error(
          'Voce não está autorizado a realizar esta operação. "Great power comes with great responsibility".'
        );
        return;
      } else {
        toast.error('Erro de conexão, tente novamente mais tarde.');
      }
    }
  };

  useEffect(() => {
    const loadUser = async () => {
      try {
        const user = await UsersService.get(userId);
        const contracts = await UsersService.getContracts(user.id);
        contracts.map((item) => Object.assign(item, { contractId: item.id }));

        const cityData = findProviderProfile(user.city, user?.state);
        const userF = { ...user, ...cityData };
        setPageState((state) => ({
          ...state,
          loading: false,
          user: userF,
          contracts,
        }));
      } catch (err) {
        setPageState((state) => ({ ...state, loading: false, hasError: true }));
      }
    };

    loadUser();
  }, [userId]);

  const fixPhoneNumber = (phone) => {
    try {
      phone = phone
        .replace('(', '')
        .replace(')', '')
        .replace('-', '')
        .replace(' ', '');
      return phone;
    } catch (error) {
      return phone;
    }
  };
  const updateCompany = async (formData, { setSubmitting }) => {
    try {
      const cityData = findProviderProfile(formData.city, formData?.state);
      const fixedPhoneNumber = fixPhoneNumber(formData.phone_number);
      const updatedUser = {
        ...formData,
        ...cityData,
        promocode: formData.promocode === '' ? null : formData.promocode,
        phone_number: fixedPhoneNumber || formData?.phone_number,
      };
      const userModifiedFields = generatePayloadFieldsModifieds(
        updatedUser,
        user
      );
      if (Object.keys(userModifiedFields).length !== 0) {
        const userUpdated = await UsersService.update(
          userId,
          userModifiedFields
        );
        if (!('error' in userUpdated)) {
          setPageState((state) => ({
            ...state,
            user: userUpdated,
          }));
        }
        toast.success(TOAST_MESSAGES.SUCCESS);
      } else {
        toast.warning(TOAST_MESSAGES.NO_DATA_UPDATED);
      }

      setIsEditing(false);
    } catch (err) {
      const { error, message } = err.data;
      isCustomer
        ? toast.error(TOAST_MESSAGES.ERROR_DEFAULT_CLIENT)
        : error === 'Request is detecting SQL Injection'
        ? toast.error(TOAST_MESSAGES.INVALID_CHARACTER)
        : toast.error(message || TOAST_MESSAGES.ERROR);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <PageView
      title={user ? `Dados de ${user.name}` : 'Dados do usuário'}
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <MainContainer>
        <UserDataCont>
          <UserForm
            onSubmit={updateCompany}
            onCancel={() => setIsEditing(false)}
            values={{ ...user }}
            readonly={!isEditing}
            handleDelete={handleDelete}
            loader={loader}
            setLoader={setLoader}
          />
          {!isEditing && (
            <Grid
              container='true'
              justify='flex-start'
            >
              <GradientButton
                paddingg={'10px 30px'}
                rectangle
                text='Alterar'
                handleClick={() => setIsEditing(true)}
              />
            </Grid>
          )}
        </UserDataCont>

        <TableCont>
          <TitleContainer>
            <Heading2>Contratos</Heading2>
          </TitleContainer>
          <SearchableTable
            columns={COLUMNS}
            rowsValues={getRows([...contracts])}
            noItemsText='Nenhum imóvel'
          />
        </TableCont>
      </MainContainer>
    </PageView>
  );
};

export default AdminUserDetails;

const MainContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

const TitleContainer = styled.div`
  margin: 0;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-start;
  > h5 {
    font-size: 1.5rem;
    color: ${theme.palette.primary.main};
    font-weight: bold;
  }
`;

const UserDataCont = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 1.5rem;
  border-radius: 10px;
`;

const TableCont = styled.div`
  width: 100%;
  margin-top: 2rem;
`;
