import * as Yup from 'yup';
import { formErrors } from '../../../helpers/form/errors';
import { formValidations } from '../../../helpers/form/validations';

const UpdateCompanyFormSchema = Yup.object().shape({
  holder: Yup.string().required(formErrors.required),
  document: Yup.string()
    .test('cpfCnpjTest', formErrors.invalidCpfOrCnpj, (v) => {
      const chars = v && v.match(/\d/g);
      if (!chars) {
        return false;
      }

      return chars.length === 11 || chars.length === 14;
    })
    .required(formErrors.required),
  phone_number: formValidations.phoneNumber(),
  street: Yup.string().required(formErrors.required),
  number: Yup.number().required(formErrors.required),
  postal_code: Yup.number().required(formErrors.required),
  neighborhood: Yup.string().required(formErrors.required),
  email: Yup.string().required(formErrors.required),
  state: Yup.string().required(formErrors.required),
  city: Yup.string().required(formErrors.required),
});

export default UpdateCompanyFormSchema;
