import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputMask from 'react-text-mask';
import { ErrorMessage, Field } from 'formik';
import { colors } from '../../../styles/colors';
import theme from '../../../theme';

const BaseLabel = ({ children, ...props }) => {
  return (
    <label {...props}>
      {children}
      <span className='checkmark' />
    </label>
  );
};

BaseLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
};

export const Label = styled(BaseLabel)`
  position: relative;
  color: ${theme.palette.primary.main};
  font-size: 20px;
  letter-spacing: 0.085em;
  display: inline-block;
  cursor: pointer;
  margin-right: 10px;

  [type='radio'] {
    position: relative;
    top: 10px;
    left: 0;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  [type='radio'] + .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid ${colors.primary};
    border-radius: 50%;
  }

  &:hover [type='radio']:not(:disabled):not(:checked) + .checkmark {
    background-color: ${colors.lightGreen};
  }

  [type='radio']:checked ~ .checkmark {
    background-color: ${colors.secondary};
  }
`;
export const LabelKPI = styled(BaseLabel)`
  position: relative;
  color: ${colors.text};
  font-size: 22px;
  letter-spacing: 0.085em;
  display: block;
  cursor: pointer;

  [type='radio'] {
    position: relative;
    top: 10px;
    left: 0;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  [type='radio'] + .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid ${colors.primary};
    border-radius: 50%;
  }

  &:hover [type='radio']:not(:disabled):not(:checked) + .checkmark {
    background-color: ${colors.lightGreen};
  }

  [type='radio']:checked ~ .checkmark {
    background-color: ${colors.secondary};
  }
`;

export const LabelDark = styled(BaseLabel)`
  position: relative;
  color: ${colors.primary};
  font-size: 20px;
  letter-spacing: 0.085em;
  display: block;
  cursor: pointer;

  [type='radio'] {
    position: relative;
    top: 10px;
    left: 0;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  [type='radio'] + .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid ${colors.primary};
    border-radius: 50%;
  }

  &:hover [type='radio']:not(:disabled):not(:checked) + .checkmark {
    background-color: ${colors.lightGreen};
  }

  [type='radio']:checked ~ .checkmark {
    background-color: ${colors.secondary};
  }
`;

const BaseCustomField = ({ name, mask, ...props }) => {
  const MaskedInput = () => (
    <Field name={name}>
      {({ field }) => (
        <InputMask
          {...field}
          {...props}
          mask={mask}
          id={name}
        />
      )}
    </Field>
  );
  return mask ? (
    MaskedInput()
  ) : (
    <Field
      name={name}
      {...props}
    />
  );
};

BaseCustomField.propTypes = {
  name: PropTypes.string.isRequired,
  mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  fullwidth: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

BaseCustomField.defaultProps = {
  mask: undefined,
  fullwidth: false,
};

export const CustomField = styled(BaseCustomField)`
  display: inline-block;
  position: relative;
  letter-spacing: 0.085em;
  background: ${colors.white};
  border: 1px solid ${colors.text};
  outline: none;
  border-radius: 2px;
  font-size: 1rem;
  margin-left: 7px;
  @media only screen and (max-width: 798px) {
    font-size: 0.8rem;
    margin-left: 0px;
  }
  padding: 5px 10px;
  color: black;
  font-weight: 300;
  width: ${(props) => (props.fullwidth ? '100%' : '300px')};

  &:disabled {
    border: none;
    padding: 0;
    color: ${colors.primary};
    background: ${(props) =>
      typeof props.background != 'undefined' ? props.background : colors.white};
  }
`;
export const CustomFieldKPI = styled(BaseCustomField)`
  position: relative;
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : 'Myriad-Pro-Condensed'};
  letter-spacing: 0.085em;
  background: ${colors.white};
  border: 1px solid ${colors.text};
  outline: none;
  border-radius: 2px;
  font-size: 30px;
  padding: 5px 10px;
  color: ${colors.primary};
  font-weight: 300;
  width: ${(props) => (props.fullwidth ? '100%' : 'auto')};

  &:disabled {
    border: none;
    padding: 0;
    color: ${colors.primary};
  }
`;

export const CustomErrorMessage = styled(ErrorMessage).attrs({
  component: 'span',
})`
  font-family: 'Myriad-Pro-Condensed';
  color: ${colors.statusDanger};
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  white-space: nowrap;
  letter-spacing: 0.085em;
`;

export const FieldWrapper = styled.div.attrs({
  className: 'd-flex flex-column',
})`
  position: relative;
  margin-top: 10px;
  padding-bottom: 25px;
`;

export const FieldWrapperRow = styled.div.attrs({
  className: 'd-flex flex-row',
})`
  position: relative;
  margin-top: 10px;
  padding-bottom: 25px;
`;
