/* Dependencies */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, CssBaseline } from '@mui/material';
import './assets/css/global.css';

/* Pages - Public */
import { NewRegister } from './pages/Public/NewRegister';
import FinishRegister from './pages/Public/FinishRegister';
import Confirmations from './pages/Public/Confirmations';
import Logout from './pages/Logout';

/* Pages - ADMIN */
import AdminUsers from './pages/Admin/Users';
import AdminSetUpDistributor from './pages/Admin/SetUpDistributor';
import AdminPaymentManagement from './pages/Admin/PaymentManagement';
import AdminUserDetails from './pages/Admin/UserDetails';
import AdminContractDetails from './pages/Admin/ContractDetails';
import AdminProducers from './pages/Admin/Producers';
import AdminContracts from './pages/Contracts';
import AdminProvider from './pages/Admin/SetUpProvider';
import AdminCustomerSuccess from './pages/Admin/CustomerSuccess';
import AdminProducerOccupation from './pages/Admin/ProducerOccupation';
import AdminProducerReport from './pages/Admin/ProducerReport';
import AdminPromocodes from './pages/Admin/Promocodes';
import AdminMassActivation from './pages/Admin/MassActivation';
import GeneratorMassActivation from './pages/Admin/GeneratorMassActivation';
import AdminLosts from './pages/Admin/Losts';
import AdminAllInvoicesManagement from './pages/Admin/ManagementBases/allInvoicesManagement';
import AdminGiftManagement from './pages/Admin/ManagementBases/GiftManagement';

/* Auth */
import { isCompanyFlora, useAuthStore, useGlobalStore } from './store';

/* Containers */
import PrivateRouter from './containers/PrivateRouter';
import PublicRouter from './containers/PublicRouter';
import {
  Dashboard,
  DashboardSidebar,
  DashboardPageView,
} from './containers/Dashboard';

/* Assets */
import { NavFloraLogo } from './components/Navbar/NavFloraLogo';

/* Helpers */
import { USER_ROLES_TYPES } from './helpers/userRoles';

import Generators from './pages/Admin/Generators';
import Cities from './pages/Admin/Cities';
import AdminCompanies from './pages/Admin/Companies';
import AdminCompanyDetails from './pages/Admin/CompanyDetails';
import Personalization from './pages/Admin/Personalization';
import { StageProvider } from './contexts/stageContext';
import { ThemeContext } from './contexts/themeContext';
import { UserProvider } from './contexts/userContext';
import { MassiveEmailShooting } from './pages/Admin/MassiveEmailShooting';
import { GlobalStyle } from './styles/global';
import { DefinePassword } from './pages/DefinePassword';
import { NewMyLocations } from './pages/Customer/NewMyLocations';
import { Property } from './pages/Customer/Property';
import { SignIn } from './pages/Public/SignIn';
import { NewForgotPassword } from './pages/Public/NewForgotPassword';
import { NewHome } from './pages/Customer/NewHome';
import { setupIugu } from './utils/iugu';
import { Pendencies } from './pages/Customer/Pendencies';
import { NewPropertyOrHolder } from './pages/Customer/NewPropertyOrHolder';
import { Profile } from './pages/Customer/Profile';
import { ButtonZendesk } from './components/ButtonZendesk';
import { BillingManual } from './pages/Admin/BillingManual';
import TryAgain from './pages/Admin/ManagementBases/MassTryAgain';
import { Recommendations } from './pages/Customer/Recommendations';
import { setupZendesk } from './utils/zendesk';
import AdminBillingManagement from './pages/Admin/ManagementBases/BillingManagement';
import theme from './theme';
import AdminExportDatabases from './pages/Admin/ManagementBases/ExportDatabases';
import { setupHotjar } from './utils/hotjar';
import { setupClickSign } from './utils/clicksign';
import RedirectTo from './containers/RedirectTo';
import NotFound from './pages/Public/NotFound';

const App = () => {
  const { setMobileView } = useGlobalStore();
  const userId = useAuthStore.getState().userAuth?.id;
  const role = useAuthStore((state) => state.userAuth?.role);
  const ThemeContextValues = useContext(ThemeContext);
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [themeValue, setthemeValue] = useState(ThemeContextValues);

  const value = useMemo(
    () => ({ themeValue, setthemeValue }),
    [themeValue, setthemeValue]
  );

  useEffect(() => {
    setupIugu();
    setupClickSign();
    setupZendesk(true);
  }, []);

  useEffect(() => {
    setupHotjar();
  }, [userId]);

  useEffect(() => {
    setMobileView(isMobile);
  }, [isMobile]);

  const publicRouters = [
    { component: SignIn, path: '/' },
    { component: RedirectTo, to: 'cadastro', path: 'economize' },
    { component: NewRegister, path: 'cadastro' },
    { component: SignIn, path: 'login' },
    { component: NewForgotPassword, path: 'esqueci-minha-senha' },
    {
      component: DefinePassword,
      path: 'definir-senha',
    },
    { component: Confirmations, path: 'confirmar' },
    { component: FinishRegister, path: 'finalizar-cadastro' },
  ];

  const customerRouters = [
    {
      component: NewHome,
      path: 'home',
    },
    { component: Pendencies, path: 'pendencias' },
    { component: Recommendations, path: 'recomendacoes' },
    { component: Profile, path: 'perfil' },
    { component: NewMyLocations, path: 'imoveis' },
    { component: Property, path: 'imovel/:propertyId' },
    {
      component: NewPropertyOrHolder,
      path: 'imoveis/novo',
      allowedRoles: [USER_ROLES_TYPES.Customer],
    },
  ];

  const adminRouters = [
    { component: AdminUsers, path: 'usuarios' },
    { component: AdminUserDetails, path: 'usuarios/:userId' },
    { component: AdminContracts, path: 'contratos' },
    { component: AdminContractDetails, path: 'contratos/:contractId' },
    { component: AdminProvider, path: 'set-up' },
    { component: AdminPaymentManagement, path: 'gestao-de-pagamentos' },
    { component: AdminCustomerSuccess, path: 'customerSuccess' },
    { component: AdminProducerOccupation, path: 'ocupacao' },
    { component: AdminProducerReport, path: 'report' },
    { component: AdminLosts, path: 'perdidos' },
    { component: AdminAllInvoicesManagement, path: 'todas-faturas' },
    { component: AdminSetUpDistributor, path: 'set-up-distribuidora' },
    { component: AdminProducers, path: 'producers' },
    { component: AdminCompanies, path: 'companies' },
    {
      component: AdminCompanyDetails,
      path: isCompanyFlora() ? 'companies/:companyId' : 'companie',
    },
    {
      component: Personalization,
      path: isCompanyFlora() ? 'personalization/:companyId' : 'personalization',
    },
    { component: AdminPromocodes, path: 'promocodes' },
    { component: Generators, path: 'gestao-oferta' },
    { component: Cities, path: 'cidades' },
    { component: AdminBillingManagement, path: 'gestaoFaturamento' },
    { component: AdminExportDatabases, path: 'download-bases' },
    { component: TryAgain, path: 'tentarNovamente' },
    { component: BillingManual, path: 'faturamentoManual' },
    { component: AdminMassActivation, path: 'massActivation' },
    { component: GeneratorMassActivation, path: 'GeneratormassActivation' },
    { component: AdminGiftManagement, path: 'giftManagement' },
    { component: MassiveEmailShooting, path: 'envio-emails' },
  ];

  const loggedRouters = [
    {
      component: Logout,
      path: 'logout',
    },
  ];

  const [routes, setRoutes] = useState();
  useEffect(() => {
    setRoutes(
      <Routes>
        {publicRouters.map((props) => PublicRouter(props))}
        <Route
          path='/*'
          element={
            <Dashboard>
              <DashboardSidebar value={value}>
                <NavFloraLogo />
              </DashboardSidebar>
              <DashboardPageView>
                <UserProvider>
                  <Routes>
                    {customerRouters.map((props) =>
                      PrivateRouter({
                        ...props,
                      })
                    )}
                  </Routes>
                </UserProvider>
                <Routes>
                  {adminRouters.map((props) =>
                    PrivateRouter({
                      ...props,
                      allowedRoles: [USER_ROLES_TYPES.Admin],
                    })
                  )}
                  {loggedRouters.map((props) => PrivateRouter(props))}
                  {/* <Route
                    path='/*'
                    element={<RedirectTo to='404' />}
                  /> */}
                </Routes>
              </DashboardPageView>
            </Dashboard>
          }
        />
        <Route
          element={<NotFound />}
          path='404'
        />
        <Route
          path='/*'
          element={<RedirectTo to='404' />}
        />
      </Routes>
    );
  }, [role]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ThemeContext.Provider value={value}>
        <BrowserRouter>
          <ButtonZendesk />
          <StageProvider>
            {routes}
            <ToastContainer
              position='top-right'
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </StageProvider>
        </BrowserRouter>
        <GlobalStyle />
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};

export default App;
