import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { StatusToggle } from '../../../../../components/Interface';
import { Label } from '../../../../../components/Interface/Form';
import FlexColContainer from '../../../../../componentsStyle/FlexColContainer';
import FlexRowContainer from '../../../../../componentsStyle/FlexRowContainer';
import { TOAST_MESSAGES } from '../../../../../helpers/toastMessages';
import CancellationReasonsServices from '../../../../../services/administrator/cancellationReasons';
import { colors } from '../../../../../styles/colors';
import Select from 'react-select';
import { MdOutlineAddBox, MdOutlineCheckBox } from 'react-icons/md';
import { TextField } from '@mui/material';
// import { useAdminCancellationReasonsStore } from "../../../../../store/admin/contractDetails/cancellationReasons.store";
import _ from 'lodash';
import adminStores from '../../../../../store/admin/contractDetails/index.store';
import theme from '../../../../../theme';

const ContractStatusToggles = ({
  selectedLocation,
  hasToShowSendProposal,
  updateLocation,
  updateContract,
  isSubmitting,
}) => {
  const {
    cancellationReasons: { cancellationReasons },
    location: { location },
    contract: { contract },
  } = adminStores();
  const [cancellationReasonsOptions, setCancellationReasonsOptions] = useState({
    options: [
      {
        label: 'Nenhum',
        value: null,
      },
    ],
    isOpen: false,
  });

  const proposalReadyCheck = () => {
    if (!location.share || parseFloat(location?.share) === 0) {
      toast.warning(TOAST_MESSAGES.NO_CONTRACT_SHARE);
      return false;
    }
    return true;
  };

  const updateContractTags = async (dataToUpdate) => {
    try {
      // eslint-disable-next-line prefer-const
      let [key, value] = Object.entries(dataToUpdate)[0];

      value = !value;

      if (value) value = new Date();
      else value = null;

      updateContract({ [key]: value });
    } catch (error) {
      console.log(error);
      toast.error(TOAST_MESSAGES.ERROR);
    }
  };

  const updateContractProposalReady = async (dataToUpdate) => {
    try {
      const [key, value] = Object.entries(dataToUpdate)[0];

      const canContinue = proposalReadyCheck();
      if (!canContinue) return;

      if (value)
        Object.assign(dataToUpdate, { refined_at: new Date().toISOString() });

      updateContract(dataToUpdate);
    } catch (error) {
      console.log(error);
      toast.error(TOAST_MESSAGES.ERROR);
    }
  };

  const updateLocationGift = async (dataToUpdate) => {
    const dataFields = ['gift'];

    try {
      let [key, value] = Object.entries(dataToUpdate)[0];

      if (dataFields.includes(key) || (value !== null && isDate(value)))
        value = value ? null : new Date().toISOString();

      updateLocation({ [key]: value });
    } catch (error) {
      toast.error(TOAST_MESSAGES.ERROR);
    }
  };

  const updateCancellationReason = async (selectedOption) => {
    try {
      const { value } = selectedOption;

      const { options } = cancellationReasonsOptions;

      const defaultValue = options.find((option) => option.value === value);

      setCancellationReasonsOptions({
        ...cancellationReasonsOptions,
        defaultValue,
      });

      updateLocation({ cancellation_reason_id: value });
    } catch (error) {
      toast.error(TOAST_MESSAGES.ERROR);
    }
  };

  const createCancellationReason = async () => {
    try {
      const { options, value, request } = cancellationReasonsOptions;

      if (request) return;

      setCancellationReasonsOptions({
        ...cancellationReasonsOptions,
        request: true,
      });
      const { id, reason: label } = await CancellationReasonsServices.create({
        reason: value,
      });
      options.push({ label, value: id });

      setCancellationReasonsOptions({
        ...cancellationReasonsOptions,
        value: '',
        false: true,
        isOpen: false,
      });
      toast.success(TOAST_MESSAGES.SUCCESS);
    } catch (error) {
      toast.error('Houve um erro na operação, tente novamente mais tarde!');
      setCancellationReasonsOptions({
        ...cancellationReasonsOptions,
        request: false,
      });
    }
  };

  const handleChangeCancellationReasonOption = (event) => {
    const { value } = event.target;
    setCancellationReasonsOptions({ ...cancellationReasonsOptions, value });
  };

  useEffect(() => {
    if (!cancellationReasons) return;

    const { options } = cancellationReasonsOptions;

    cancellationReasons.map(({ id, reason }) =>
      options.push({ label: reason, value: id })
    );

    const defaultValue = options.find(
      ({ value }) => value == selectedLocation.cancellation_reason_id
    );

    setCancellationReasonsOptions({
      ...cancellationReasonsOptions,
      options: _.uniqBy(cancellationReasonsOptions.options, 'value'),
      defaultValue,
    });
  }, [cancellationReasons]);

  return (
    <StatusTogglesContainer>
      <FlexRowContainer
        style={{
          justifyContent: 'space-between',
          alignItems: 'end',
          marginTop: 10,
          paddingBottom: 25,
        }}
      >
        <Label style={{ margin: 0 }}>Gift:</Label>
        <StatusToggle
          active={selectedLocation?.gift}
          labelText={selectedLocation?.gift ? 'Gift Ativo' : 'Gift Desativado'}
          statusColors={[theme.palette.secondary.main, theme.palette.gray.main]}
          handleChange={() =>
            updateLocationGift({ gift: selectedLocation?.gift })
          }
          isSubmitting={isSubmitting?.gift}
        />
      </FlexRowContainer>

      <FlexRowContainer
        style={{
          justifyContent: 'space-between',
          alignItems: 'end',
          marginTop: 10,
          paddingBottom: 25,
        }}
      >
        <Label style={{ margin: 0 }}>Tag 1:</Label>
        <StatusToggle
          active={contract?.tag_1}
          labelText={contract?.tag_1 ? 'Já assinou' : 'Não assinou'}
          statusColors={[theme.palette.secondary.main, theme.palette.gray.main]}
          handleChange={() => updateContractTags({ tag_1: contract?.tag_1 })}
          isSubmitting={isSubmitting?.tag_1}
        />
      </FlexRowContainer>

      <FlexRowContainer
        style={{
          justifyContent: 'space-between',
          alignItems: 'end',
          marginTop: 10,
          paddingBottom: 25,
        }}
      >
        <Label style={{ margin: 0 }}>Tag 2:</Label>
        <StatusToggle
          active={contract.tag_2}
          labelText={contract.tag_2 ? 'Com conta' : 'Sem conta'}
          statusColors={[theme.palette.secondary.main, theme.palette.gray.main]}
          handleChange={() => updateContractTags({ tag_2: contract?.tag_2 })}
          isSubmitting={isSubmitting?.tag_2}
        />
      </FlexRowContainer>

      <FlexRowContainer
        exRowContainer
        style={{
          justifyContent: 'space-between',
          alignItems: 'end',
          marginTop: 10,
          paddingBottom: 25,
        }}
      >
        <Label style={{ margin: 0 }}>Status Proposta:</Label>
        <StatusToggle
          active={contract?.proposal_ready}
          labelText={contract?.proposal_ready ? 'Refinado' : 'Não Refinado'}
          statusColors={[theme.palette.secondary.main, theme.palette.gray.main]}
          handleChange={() =>
            hasToShowSendProposal(false)
              ? updateContractProposalReady({
                  proposal_ready: !contract?.proposal_ready,
                })
              : toast.warning(TOAST_MESSAGES.NO_CONTRACT_FULL_FIELD)
          }
          isSubmitting={isSubmitting?.proposal_ready}
        />
      </FlexRowContainer>

      {contract.status === 'cancelled' && (
        <FlexColContainer>
          <Label style={{ margin: 0 }}>Motivo de cancelamento:</Label>
          <FlexRowContainer
            style={{
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <SelectContainer>
              <Select
                options={cancellationReasonsOptions.options}
                value={cancellationReasonsOptions.defaultValue}
                onChange={(el) => updateCancellationReason(el)}
                style={{ flexGrow: 1 }}
                fullWidth={true}
              />
            </SelectContainer>
            {cancellationReasonsOptions?.value ? (
              <MdOutlineCheckBox
                size='35px'
                onClick={() => createCancellationReason()}
              />
            ) : (
              <MdOutlineAddBox
                size='35px'
                onClick={() =>
                  setCancellationReasonsOptions({
                    ...cancellationReasonsOptions,
                    isOpen: !cancellationReasons.isOpen,
                  })
                }
              />
            )}
          </FlexRowContainer>
        </FlexColContainer>
      )}

      {cancellationReasonsOptions.isOpen && (
        <TextField
          id='outlined-size-small'
          size='small'
          value={cancellationReasonsOptions.value}
          style={{ marginTop: '0.5rem', width: '100%' }}
          onChange={(el) => handleChangeCancellationReasonOption(el)}
          fullWidth
        />
      )}
    </StatusTogglesContainer>
  );
};
export default ContractStatusToggles;

const StatusTogglesContainer = styled.div``;
const SelectContainer = styled.div`
  width: 100%;
`;
