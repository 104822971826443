import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import FlexRowContainer from '../../componentsStyle/FlexRowContainer';
import { Alert, Button, CircularProgress } from '@mui/material';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';
import { TOAST_MESSAGES } from '../../helpers/toastMessages';
import ExportingDatabasesService from '../../services/administrator/ManagementBases/ExportingDatabasesService';
import theme from '../../theme';

const DownloadBases = ({
  hasUpdateBase = false,
  indentifier,
  filter = [],
  period = {},
  refreshedAt = '',
  style = {},
  filterBasesManagement = {},
  sharedRefreshedAt = { refreshedAt: null, setRefreshedAt: () => {} },
}) => {
  const [localRefreshedAt, setLocalRefreshedAt] = useState(null);

  useEffect(() => {
    setLocalRefreshedAt(null);
  }, [indentifier]);

  const finalRefreshedAt = useMemo(
    () => sharedRefreshedAt.refreshedAt ?? localRefreshedAt ?? refreshedAt,
    [sharedRefreshedAt.refreshedAt, localRefreshedAt, refreshedAt]
  );

  const refreshAtDate = useMemo(
    () =>
      new Date(finalRefreshedAt).toLocaleDateString('pt-br', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      }),
    [finalRefreshedAt]
  );

  const [isUpdatingBase, setIsUpdatingBase] = useState(false);
  const [isExporting, setIsExporting] = useState({
    email: false,
    sync: false,
  });

  const updateBase = async () => {
    setIsUpdatingBase(true);
    try {
      const payload = {};
      for (let key in filterBasesManagement) {
        const value = filterBasesManagement[key];
        payload['filter'] = { ...payload['filter'], [key]: value };
      }
      filter.forEach((item) => {
        const {
          accessor,
          term: { value },
        } = item;

        payload['filter'] = { ...payload['filter'], [accessor]: value };
      });
      for (let key in period) {
        payload[key] = new Date(period[key]).toISOString();
      }
      const { response } = await ExportingDatabasesService.updateBase(
        indentifier,
        payload
      );

      const refreshedAt = response.find((el) => el?.refreshed_at)?.refreshed_at;

      if (refreshedAt)
        if (sharedRefreshedAt.refreshedAt)
          sharedRefreshedAt.setRefreshedAt(refreshedAt);
        else setLocalRefreshedAt(refreshedAt);

      toast.success(TOAST_MESSAGES.SUCCESS);
    } catch (error) {
      if (error.message === 'Network Error')
        toast.warn(TOAST_MESSAGES.PROCESS_IN_PROGRESS);
      else toast.error(TOAST_MESSAGES.ERROR);
    } finally {
      setIsUpdatingBase(false);
    }
  };

  const downloadHandler = async (receiveMode) => {
    setIsExporting((prevState) => ({ ...prevState, [receiveMode]: true }));
    try {
      const result = await ExportingDatabasesService.download(indentifier, {
        refreshed_at: finalRefreshedAt,
      });

      if (receiveMode === 'sync') {
        const link = document.createElement('a');
        link.setAttribute('href', result?.url);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (receiveMode === 'email') {
        toast.success('Dados enviados por email');
      }
    } catch {
      toast.error(TOAST_MESSAGES.ERROR);
    } finally {
      setIsExporting((prevState) => ({ ...prevState, [receiveMode]: false }));
    }
  };

  return (
    <Container style={style}>
      {hasUpdateBase && refreshedAt ? (
        <Alert
          severity='info'
          style={{ margin: '0 auto' }}
          className='alert'
        >
          Última atualização: {refreshAtDate}
        </Alert>
      ) : null}
      <ButtonContainer>
        {hasUpdateBase ? (
          <Button
            size='small'
            variant='outlined'
            onClick={updateBase}
          >
            <FlexRowContainer
              style={{
                gap: '1rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Atualizar base
              {isUpdatingBase ? (
                <CircularProgress style={{ width: 16, height: 16 }} />
              ) : null}
            </FlexRowContainer>
          </Button>
        ) : null}

        <Button
          size='small'
          variant='contained'
          onClick={() => downloadHandler('sync')}
          style={{
            background: theme.palette.secondary.main,
            color: theme.palette.primary.main,
          }}
          disableElevation
        >
          <FlexRowContainer
            style={{
              gap: '1rem',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Dowload
            {isExporting?.sync ? (
              <CircularProgress
                style={{ color: '#fff', width: 16, height: 16 }}
              />
            ) : null}
          </FlexRowContainer>
        </Button>

        {/* <Button
          size='small'
          variant='contained'
          onClick={() => downloadHandler('email')}
        >
          <FlexRowContainer
            style={{
              gap: '1rem',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Enviar por email
            {isExporting?.email ? (
              <CircularProgress
                style={{ color: '#fff', width: 16, height: 16 }}
              />
            ) : null}
          </FlexRowContainer>
        </Button> */}
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 1rem;
  ${(props) =>
    props.style?.flexDirection === 'row' &&
    css`
      align-items: center;
      justify-content: flex-end;

      .alert {
        margin: 0 !important;
      }
    `};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

DownloadBases.propTypes = {
  indentifier: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  hasUpdateBase: PropTypes.bool,
  filter: PropTypes.array,
  period: PropTypes.object,
  refreshedAt: PropTypes.string,
  style: PropTypes.object,
  filterBasesManagement: PropTypes.object,
  sharedRefreshedAt: PropTypes.object,
};

export default DownloadBases;
